import { Button } from "@coconut-xr/apfel-kruemel";
import { Container, Text } from "@coconut-xr/koestlich";
import { useXR, type XRState } from "@coconut-xr/natuerlich/react";
import { Quaternion, Vector3 } from "three";
import { useSettingsStore } from "../SettingsStore";
import { useAnchorTransform } from "../engine/AnchorTransform";
import { HandleType } from "../engine/HandleType";
import {
	computeRoomGeometry,
	type RoomInfo,
} from "../engine/computeRoomGeometry";
import { useAddSceneItem } from "../engine/useAddSceneItem";
import { useColocatorState } from "../headset/colocatorTool";

export const SettingsMenu = () => {
	const { enable } = useColocatorState();
	const { showOrigins, toggleShowOrigins, toggleShowRoom } = useSettingsStore();
	const state = useXR();
	const { inverseTransform: inverseAnchor } = useAnchorTransform();
	const addSceneItem = useAddSceneItem();

	return (
		<Container>
			<Text>Settings</Text>
			<Button onClick={() => enable()}>
				<Text>Enable Colocator</Text>
			</Button>
			<Button onClick={() => toggleShowOrigins()}>
				<Text>Show Origins</Text>
			</Button>
			<Button onClick={() => toggleShowRoom()}>
				<Text>Show Room</Text>
			</Button>
			<Button
				onClick={async () => {
					if (!inverseAnchor) {
						console.warn("No anchor transform");
						return;
					}
					const geometry = await computeRoomGeometry(state as Partial<XRState>);
					if (!geometry) {
						console.warn("No geometry");
						return;
					}
					const position = new Vector3();
					const quaternion = new Quaternion();
					const scale = new Vector3();
					inverseAnchor.decompose(position, quaternion, scale);
					addSceneItem<RoomInfo>("room", geometry, {
						scale: scale.toArray(),
						position: position.toArray(),
						quaternion: quaternion.toArray() as [
							number,
							number,
							number,
							number,
						],
						handleType: HandleType.NONE,
					});
				}}
			>
				<Text>Add Room</Text>
			</Button>
		</Container>
	);
};
