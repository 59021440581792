import { useFrame } from "@react-three/fiber";
import { isHost } from "playroomkit";
import { Vector3 } from "three";
import { ECS, type Entity } from "./state";

const workers = ECS.world.where(({ type }) => type === "workerBee");
const hives = ECS.world.where(({ type }) => type === "hive");
const resources = ECS.world.where(({ type }) => type === "resource");

const loadingDistance = 0.1;
const workerSpeed = 0.5;
const randomDirectionHelper = new Vector3();
export const WorkersSystem = () => {
	useFrame((_, dt) => {
		if (!isHost()) {
			return;
		}

		for (const resource of resources) {
			if (!resource.loaded) {
				resource.loaded = 100;
			}
			if (resource.loaded < 100) {
				resource.loaded += dt;
			}
		}

		function findNearestResource(worker: Entity) {
			let nearestResource = null;
			let nearestDistance = Number.MAX_SAFE_INTEGER;
			for (const resource of resources) {
				const resourcePos = resource.transform.position;
				const workerPos = worker.transform.position;
				const distance = resourcePos.distanceTo(workerPos);
				if (distance < nearestDistance) {
					nearestResource = resource;
					nearestDistance = distance;
				}
			}
			return nearestResource;
		}

		for (const worker of workers) {
			if (!worker.target) {
				worker.target = findNearestResource(worker)?.id;
			}
			if (worker.target === "hive") {
				const hive = hives.first;
				if (hive) {
					const workerPos = worker.transform.position;
					const hivePos = hive.transform.position;
					const distance = hivePos.distanceTo(workerPos);
					if (distance < loadingDistance) {
						if (!hive.loaded) {
							hive.loaded = 0;
						}
						hive.loaded += worker.loaded ?? 0;
						worker.loaded = 0;
						worker.target = findNearestResource(worker)?.id;
					} else {
						const direction = hivePos.clone().sub(workerPos).normalize();
						if (!worker.velocities) {
							worker.velocities = {
								linear: { x: 0, y: 0, z: 0 },
								angular: { x: 0, y: 0, z: 0 },
							};
						}
						worker.rapier?.current?.setLinvel(
							{
								x: direction.x * workerSpeed,
								y: direction.y * workerSpeed,
								z: direction.z * workerSpeed,
							},
							true,
						);
					}
				}
			} else {
				const resource = resources.where(
					({ id }) => id === worker.target,
				).first;
				if (resource) {
					const workerPos = worker.transform.position;
					const resourcePos = resource.transform.position;
					const distance = resourcePos.distanceTo(workerPos);
					if (distance < loadingDistance) {
						if (!resource.loaded) {
							resource.loaded = 0;
						}
						if (!worker.loaded) {
							worker.loaded = 0;
						}
						worker.loaded += resource.loaded ?? 0;
						resource.loaded = 0;
						worker.target = "hive";
					} else {
						const direction = resourcePos.clone().sub(workerPos).normalize();
						if (!worker.velocities) {
							worker.velocities = {
								linear: { x: 0, y: 0, z: 0 },
								angular: { x: 0, y: 0, z: 0 },
							};
						}
						if (worker.rapier?.current) {
							worker.rapier.current.mass = () => 0;
							worker.rapier.current.setLinvel(
								{
									x: direction.x * workerSpeed,
									y: direction.y * workerSpeed,
									z: direction.z * workerSpeed,
								},
								true,
							);
						}
					}
				}
			}
		}
	});

	return null;
};
