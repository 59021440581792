import JSZip from 'jszip'
import { DirectionalLight, LoadingManager, Scene } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

function checkStatus(response: Response) {
  // From: https://gist.github.com/irbull/42f3bd7a9db767ce72a770ded9a5bdd1
  if (!response.ok) {
    throw new Error(`HTTP ${response.status} - ${response.statusText}`)
  }
  return response
}
function getExtension(filename: string) {
  return filename.toLowerCase().split('.').pop()
}
async function getFileUrl(file: JSZip.JSZipObject) {
  const blob = await file.async('blob')
  const url = URL.createObjectURL(blob)
  return url
}

let db: IDBDatabase

const dbName = 'sketchfab-model-cache'
const storeName = 'models'

async function initDB() {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open(dbName)
    request.onerror = () => reject(request.error)
    request.onsuccess = () => resolve(request.result)
    request.onupgradeneeded = () => {
      const db = request.result
      db.createObjectStore(storeName)
    }
  })
}

async function getFromCache(uri: string): Promise<ArrayBuffer | undefined> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readonly')
    const store = transaction.objectStore(storeName)
    const request = store.get(uri)
    request.onerror = () => reject(request.error)
    request.onsuccess = () => {
      resolve(request.result)
    }
  })
}

async function saveToCache(uri: string, arrayBuffer: ArrayBuffer) {
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite')
    const store = transaction.objectStore(storeName)
    const request = store.put(arrayBuffer, uri)
    request.onerror = () => reject(request.error)
    request.onsuccess = () => resolve()
  })
}

export async function downloadSketchFabModel(uri: string): Promise<Scene> {
  await initDB().then((database) => (db = database))
  console.log('Downloading', uri)

  const cachedArrayBuffer = await getFromCache(uri)
  if (cachedArrayBuffer) {
    const scene = await loadSceneFromArrayBuffer(cachedArrayBuffer)
    console.log('Loaded from cache', uri, scene)
    return scene
  }

  const configResponse = await fetch(`${uri}/download`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token e9af06a0199d441e8b15c01472c27aea',
    },
  })
  const json = await configResponse.json()

  const response = await fetch(json.gltf.url)
  checkStatus(response)
  const arrayBuffer = await response.arrayBuffer()

  await saveToCache(uri, arrayBuffer)

  const scene = await loadSceneFromArrayBuffer(arrayBuffer)
  return scene
}

async function loadSceneFromArrayBuffer(arrayBuffer: ArrayBuffer): Promise<Scene> {
  const result = await JSZip.loadAsync(arrayBuffer)

  const files = Object.values(result.files).filter((item) => !item.dir)
  const entryFile = files.find((f) => getExtension(f.name) === 'gltf')
  if (!entryFile) {
    throw new Error('No GLTF file found')
  }
  // Create blobs for every file resource
  const blobUrls: { [key: string]: string } = {}
  for (const file of files) {
    console.log(`Loading ${file.name}...`)
    blobUrls[file.name] = await getFileUrl(file)
  }
  const fileUrl = blobUrls[entryFile.name]

  const scene = new Scene()
  // Re-add the light
  const light = new DirectionalLight(0xffffff, 1)
  scene.add(light)
  light.position.set(1.7, 1, -1)

  const loadingManager = new LoadingManager()
  loadingManager.setURLModifier((url) => {
    const parsedUrl = new URL(url)
    const origin = parsedUrl.origin
    const path = parsedUrl.pathname
    const relativeUrl = path.replace(`${origin}/`, '')
    if (blobUrls[relativeUrl] !== undefined) {
      return blobUrls[relativeUrl]
    }

    return url
  })
  const gltfLoader = new GLTFLoader(loadingManager)

  return new Promise((resolve, reject) => {
    gltfLoader.load(
      fileUrl,
      (gltf) => {
        scene.add(gltf.scene)
        resolve(scene)
      },
      undefined,
      reject
    )
  })
}
