import { Matrix4, Quaternion, Vector3 } from "three";
import { Behaviours } from "../behaviours/Behaviours";
import { HandleType } from "./HandleType";
import type { PersistableScene } from "./PersistableScene";
import { SerializedEntity } from "./serializeEntity";

export function makeTransform(
	position: Vector3,
	quaternion: Quaternion,
	scale: Vector3,
): number[] {
	return new Matrix4().compose(position, quaternion, scale).toArray();
}

export const defaultScene: PersistableScene = {
	items: [
		{
			id: "1",
			behaviours: [Behaviours.SnapToWallBehaviour],
			type: "mainMenu",
			transform: makeTransform(
				new Vector3(0, 1, 0),
				new Quaternion(),
				new Vector3(1, 1, 1),
			),
			handleType: HandleType.SMALL,
			selectedTab: 2,
			isNetworked: false,
			children: [],
		} as SerializedEntity,
		{
			id: "2",
			behaviours: [Behaviours.SnapToWallBehaviour],
			type: "pointLight",
			color: "red",
			intensity: 1,
			transform: makeTransform(
				new Vector3(2, 1, 0),
				new Quaternion(),
				new Vector3(3, 3, 3),
			),
			handleType: HandleType.FULL,
			selectedTab: 0,
			isNetworked: false,
			children: [],
		} as SerializedEntity,
		// touchViz: {
		// 	type: "touchViz",
		// 	transform: {
		// 		position: [0, 0, 0],
		// 		quaternion: [0, 0, 0, 1],
		// 		scale: [1, 1, 1],
		// 	},
		// 	isNetworked: false,
		// 	handleType: HandleType.NONE,
		// 	children: [],
		// } as SceneItem,
		// room: SampleRoom,
		// '0': {
		//   type: 'pointCloud',
		//   url: 'https://tiles.map51.de/3dtiles/fml/c_t04_1/tileset.json',
		//   transform: {
		//     position: [0, 1, 0],
		//     quaternion: [0, 0, 0, 1],
		//     scale: [0.001, 0.001, 0.001],
		//   },
		//   isNetworked: false,
		//   handleType: HandleType.FULL,
		//   children: [],
		// } as SceneItem,
	],
};
