import { useEffect, useState } from "react";
import type { Scene } from "three";
import { downloadSketchFabModel } from "./downloadSketchFabModel";

export interface SketchFabItemProps {
	url: string;
}

export const SketchfabItem = ({ url }: SketchFabItemProps) => {
	const [scene, setScene] = useState<Scene>();
	useEffect(() => {
		downloadSketchFabModel(url)
			.then((scene) => {
				setScene(scene);
			})
			.catch((error) => {
				console.trace(error);
			});
	}, [url]);
	if (!scene) {
		return null;
	}
	return <primitive object={scene} />;
};
