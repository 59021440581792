import { Button } from "@coconut-xr/apfel-kruemel";
import { Text } from "@coconut-xr/koestlich";
import { me } from "playroomkit";
import type { Map3dProps } from "../components/livekit/GoogleMaps";
import type { ImageTileProps } from "../components/livekit/ImageTile";
import type { PointCloudProps } from "../components/livekit/PointCloud";
import { HandleType } from "../engine/HandleType";
import type { PlayerAvatarProps } from "../engine/PlayerAvatar";
import { useAddSceneItem } from "../engine/useAddSceneItem";
import type { PointLightProps } from "./PointLight";

export const SpawnerMenu = () => {
	const addSceneItem = useAddSceneItem();

	return (
		<>
			<Text>Spawn Items</Text>
			<Button
				onClick={() => {
					addSceneItem<PointCloudProps>(
						"pointCloud",
						{
							url: "https://tiles.map51.de/3dtiles/fml/c_t04_1/tileset.json",
						},
						{
							position: [0, 1, 0],
							handleType: HandleType.FULL,
							// forceUpright: true,
							quaternion: [0, 0, 0, 1],
							scale: [1, 1, 1],
						},
					);
				}}
			>
				<Text>FML PC</Text>
			</Button>
			<Button
				onClick={() => {
					addSceneItem<PlayerAvatarProps>(
						"avatar",
						{
							modelSrc: "https://readyplayerme.github.io/visage/male.glb",
						},
						{
							avatarState: {},
							overrideId: `${me().id}-avatar`,
							position: [0, 0, 0],
							handleType: HandleType.NONE,
							// forceUpright: true,
							quaternion: [0, 0, 0, 1],
							scale: [1, 1, 1],
						},
					);
				}}
			>
				<Text>Avatar</Text>
			</Button>
			<Button
				onClick={() => {
					addSceneItem<PointCloudProps>(
						"pointCloud",
						{
							url: "https://assets.ion.cesium.com/43978/tileset.json",
						},
						{
							position: [0, 1, 0],
							handleType: HandleType.FULL,
							// forceUpright: true,
							quaternion: [0, 0, 0, 1],
							scale: [1, 1, 1],
						},
					);
				}}
			>
				<Text>City PC</Text>
			</Button>
			<Button
				onClick={() => {
					addSceneItem<Map3dProps>(
						"map3d",
						{
							lon: 11.00783,
							lat: 49.59099,
							alt: 0,
						},
						{
							handleType: HandleType.FULL,
							position: [0, 1, 0],
							quaternion: [0, 0, 0, 1],
							scale: [1, 1, 1],
						},
					);
				}}
			>
				<Text>Spawn Map3d</Text>
			</Button>
			<Button
				onClick={() => {
					addSceneItem<PointLightProps>(
						"pointLight",
						{
							color: "red",
							intensity: 1,
						},
						{
							handleType: HandleType.FULL,
							position: [0, 1, 0],
							physical: "fixed",
							quaternion: [0, 0, 0, 1],
							scale: [1, 1, 1],
						},
					);
				}}
			>
				<Text>Spawn Pointlight</Text>
			</Button>
			<Button
				onClick={() => {
					addSceneItem<ImageTileProps>(
						"imageTile",
						{
							url: "/fml_map.jpg",
						},
						{
							position: [0, 1, 0],
							quaternion: [0, 0, 0, 1],
							scale: [1, 1, 1],
						},
					);
				}}
			>
				<Text>Spawn Poster</Text>
			</Button>
		</>
	);
};
