import { RPC, type PlayerState } from "playroomkit";
import { useEffect } from "react";
import { ECS, FORCE_UPDATE } from "./ecs/state";

export function useRPCGrabItem() {
	useEffect(() => {
		return RPC.register(
			"grabItem",
			async (itemId: string, caller: PlayerState) => {
				for (const entity of ECS.world) {
					if (entity.id !== itemId) {
						continue;
					}
					// console.log("grabbing item", itemId);
					entity.grabbedBy = caller.id;
					entity.lastSync = FORCE_UPDATE;
					return true;
				}
				return false;
			},
		);
	}, []);

	useEffect(() => {
		return RPC.register("releaseItem", async (itemId: string) => {
			// console.log("releasing item", itemId);
			for (const entity of ECS.world) {
				if (entity.id !== itemId) {
					continue;
				}
				entity.grabbedBy = undefined;
				entity.lastSync = FORCE_UPDATE;
				// console.log("releasing item", itemId, entity.grabbedBy);
				return true;
			}
			return false;
		});
	}, []);
}
