import {
	RPC,
	getState,
	isHost,
	setState,
	useIsHost,
	type PlayerState,
} from "playroomkit";
import React, { useEffect } from "react";
import { Matrix4, Quaternion, Vector3 } from "three";
import { compressEntity } from "./compressEntity";
import { decompressEntity } from "./decompressEntity";
import { ECS } from "./ecs/state";
import { ItemContext } from "./useItemState";

export interface ItemTransform {
	matrix: Matrix4;
	position: Vector3;
	quaternion: Quaternion;
	scale: Vector3;
}

const IdentityTransform: ItemTransform = {
	matrix: new Matrix4().identity(),
	position: new Vector3(),
	quaternion: new Quaternion(),
	scale: new Vector3(1, 1, 1),
};

const transformsByItem = new Map<string, ItemTransform>();
const lastMatrixByItem = new Map<string, number[]>();
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
(globalThis as any).transformsByItem = transformsByItem;

function getItemTransform(itemId: string): ItemTransform {
	if (!transformsByItem.has(itemId)) {
		transformsByItem.set(itemId, {
			matrix: new Matrix4(),
			position: new Vector3(),
			quaternion: new Quaternion(),
			scale: new Vector3(1, 1, 1),
		});
	}
	return transformsByItem.get(itemId) ?? IdentityTransform;
}

function updateItem(itemId: string, transform: number[]) {
	const t = getItemTransform(itemId);
	t.matrix.fromArray(transform);
	t.matrix.decompose(t.position, t.quaternion, t.scale);
}

RPC.register(
	"updateItemTransform",
	async (
		data: { itemId: string; transform: number[] },
		caller: PlayerState,
	) => {
		// console.log("Updating item transform", data.itemId, data.transform);
		updateItem(data.itemId, data.transform);
	},
);

/**
 * Get the transforms for the given item IDs.
 * @param data.itemIds The item IDs to get the transforms for.
 * @returns A map of item IDs to transforms.
 */
export const useRPCResync = () => {
	const isHost = useIsHost();
	useEffect(() => {
		if (!isHost) {
			console.log("Resyncing state from host");
			resyncStateFromHost();
		} else {
			console.log("I am the host");
		}
	}, [isHost]);

	useEffect(() => {
		return RPC.register("resync", async () => {
			const compressedEntities = ECS.world.entities.map((entity) => {
				return compressEntity(entity);
			});
			console.log("Sending transforms", compressedEntities);
			return compressedEntities;
		});
	}, []);
};

export async function resyncStateFromHost() {
	if (isHost()) {
		return;
	}
	ECS.world.clear();
	const entities = await RPC.call("resync", {}, RPC.Mode.HOST);
	console.log("received", entities);
	for (const entity of entities) {
		ECS.world.add(decompressEntity(entity));
	}
}

export function updateItemTransforms() {
	const items = getState("scene") ?? ([] as string[]);
	// console.log(items);
	for (const id of items) {
		const transform = getState(`itemTransforms.${id}`);
		const lastMatrix = lastMatrixByItem.get(id);
		if (transform && (!lastMatrix || !areArraysEqual(transform, lastMatrix))) {
			updateItem(id, transform);
			lastMatrixByItem.set(id, transform);
		}
	}
}

function areArraysEqual(a: number[], b: number[]): boolean {
	if (a.length !== b.length) return false;
	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) return false;
	}
	return true;
}

export async function setItemTransform(
	itemId: string,
	matrix: number[] | Matrix4,
) {
	// updateItem(itemId, matrix instanceof Matrix4 ? matrix.toArray() : matrix);
	// console.log("Setting item transform", itemId, matrix);
	setState(
		`itemTransforms.${itemId}`,
		matrix instanceof Matrix4 ? matrix.toArray() : matrix,
		false,
	);
	// return await RPC.call(
	// 	"updateItemTransform",
	// 	{
	// 		itemId,
	// 		transform: matrix instanceof Matrix4 ? matrix.toArray() : matrix,
	// 	},
	// 	RPC.Mode.OTHERS,
	// );
}

export const useItemTransform = (): ItemTransform => {
	const id = React.useContext(ItemContext);
	return getItemTransform(id);
};
