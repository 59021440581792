import { useEntities } from "miniplex-react";
import { NetworkedItem } from "./NetworkedItem";
import { ECS } from "./ecs/state";

export const EntitiesRenderer = () => {
	const entities = useEntities(ECS.world);
	return (
		<ECS.Entities in={entities}>
			<NetworkedItem />
		</ECS.Entities>
	);
};
