import { useIsHost } from "playroomkit";
import { useEffect } from "react";
import { Matrix4, Vector3 } from "three";
import { HandleType } from "../engine/HandleType";
import { ECS } from "../engine/ecs/state";
import { useAddSceneItem } from "../engine/useAddSceneItem";

const ForwardHelper = new Vector3();
const RotationMatrixHelper = new Matrix4();
export const BallCannon = () => {
	const addSceneItem = useAddSceneItem();
	const cannon = ECS.useCurrentEntity();

	const isHost = useIsHost();
	useEffect(() => {
		console.log("starting interval");
		if (!isHost) {
			return undefined;
		}
		const interval = setInterval(() => {
			ForwardHelper.set(1, 0, 0);
			RotationMatrixHelper.extractRotation(cannon.transform.matrix);
			ForwardHelper.applyMatrix4(RotationMatrixHelper);
			ForwardHelper.normalize();
			addSceneItem(
				"ball",
				{},
				{
					transform: cannon.transform.matrix,
					physical: "dynamic",
					handleType: HandleType.FULL,
					scale: [0.2, 0.2, 0.2],
					velocities: {
						linear: ForwardHelper.multiplyScalar(1),
						angular: { x: 0, y: 0, z: 0 },
					},
				},
			);
		}, 1500);

		return () => clearInterval(interval);
	}, [addSceneItem, cannon, isHost]);

	return (
		<mesh>
			<boxGeometry args={[0.1, 0.1, 0.1]} />
			<meshStandardMaterial color="yellow" />
		</mesh>
	);
};

export const Ball = () => {
	const ball = ECS.useCurrentEntity();

	useEffect(() => {
		const timeout = setTimeout(() => {
			ECS.world.remove(ball);
		}, 20000);

		return () => clearTimeout(timeout);
	}, [ball]);

	return (
		<mesh>
			<boxGeometry args={[0.1, 0.1, 0.1]} />
			<meshStandardMaterial color="red" />
		</mesh>
	);
};
