import { useEffect, useRef, useState } from "react";

export const DeepgramMicrophone = () => {
	const [transcript, setTranscript] = useState<string>("");
	const isListening = useRef<boolean>(false);
	const [socket, setSocket] = useState<WebSocket | null>(null);
	const mediaRecorderRef = useRef<MediaRecorder | null>(null);

	useEffect(() => {
		const newSocket = new WebSocket("wss://api.deepgram.com/v1/listen", [
			"token",
			"243083a8bcc7a6b2ad68ec9b9073495cb13ae59b",
		]);
		newSocket.onopen = () => console.log("WebSocket connection opened.");
		newSocket.onmessage = (event) => {
			const data = JSON.parse(event.data);
			console.log(data);
			if (data.channel?.alternatives) {
				const transcript = data.channel.alternatives[0].transcript;
				if (transcript && data.is_final) {
					setTranscript((prevTranscript) => `${prevTranscript} ${transcript}`);
				}
			}
		};
		newSocket.onclose = () => console.log("WebSocket connection closed.");
		newSocket.onerror = (error) => console.error("WebSocket error:", error);
		setSocket(newSocket);

		return () => {
			newSocket.close();
		};
	}, []);

	useEffect(() => {
		const startRecording = async () => {
			console.log("Starting continuous recording");
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					audio: true,
				});
				const newMediaRecorder = new MediaRecorder(stream);
				mediaRecorderRef.current = newMediaRecorder;

				newMediaRecorder.addEventListener("dataavailable", (event) => {
					if (
						event.data.size > 0 &&
						isListening.current &&
						socket?.readyState === WebSocket.OPEN
					) {
						socket.send(event.data);
					}
				});
				newMediaRecorder.start(250); // Start recording and send data every 250ms
			} catch (error) {
				console.error("Failed to start continuous recording:", error);
			}
		};
		startRecording();

		return () => {
			mediaRecorderRef.current?.stop();
			for (const track of mediaRecorderRef.current?.stream.getTracks() || []) {
				track.stop();
			}
		};
	}, [socket]);

	const handleMouseDown = () => {
		isListening.current = true;
	};

	const handleMouseUp = () => {
		isListening.current = false;
	};

	return (
		<div>
			<button
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				onClick={() => console.log("Click")}
				color="primary"
				type="button"
				className="btn btn-primary"
			>
				Talk
			</button>
			<div>{transcript}</div>
		</div>
	);
};
