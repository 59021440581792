import { RigidBody } from "@react-three/rapier";
import { useEffect, useState } from "react";
import {
	Box2,
	BoxGeometry,
	Matrix4,
	Quaternion,
	Shape,
	ShapeGeometry,
	Vector2,
	Vector3,
	type BufferGeometry,
} from "three";
import { useSettingsStore } from "../SettingsStore";
import type { PlaneInfo, RoomInfo } from "../engine/computeRoomGeometry";
import { useItemState } from "../engine/useItemState";
import { holodeckShader } from "./holodeckShader";

export const Room = () => {
	const [props] = useItemState<RoomInfo>();
	return (
		<mesh>
			{props.planes.map((plane, n) => {
				switch (plane.semanticLabel) {
					case "ceiling":
					case "wall":
					case "floor":
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						return <Wall key={n} {...plane} colorWrite={true} />;
					case "window":
					case "door":
					case "wall art":
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						return <Wall key={n} {...plane} colorWrite={false} />;
					case "couch":
					case "storage":
					case "table":
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						return <Box key={n} {...plane} colorWrite={false} />;
					// return (
					// 	<Table
					// 		key={n}
					// 		{...plane}
					// 		// colorWrite={true}
					// 		legDepth={0.1}
					// 		legWidth={0.05}
					// 	/>
					// );
					default:
						return null;
				}
			})}
		</mesh>
	);
};

const boxHelper = new Box2();
const sizeHelper = new Vector2();

export const Wall = ({
	points,
	transform,
	colorWrite,
}: PlaneInfo & { colorWrite: boolean }) => {
	const [geometry, setGeometry] = useState<BufferGeometry | null>(null);
	const [t] = useState<Matrix4>(new Matrix4().fromArray(transform));
	const { showRoom } = useSettingsStore();
	useEffect(() => {
		const shape = new Shape();
		const points2 = points.map(([x, z]) => new Vector2(x, z));
		boxHelper.setFromPoints(points2);
		boxHelper.getSize(sizeHelper);

		for (const point of points2) {
			point.sub(boxHelper.min).divide(sizeHelper);
		}

		shape.setFromPoints(points2);
		const tempGeometry = new ShapeGeometry(shape);
		tempGeometry.scale(sizeHelper.x, sizeHelper.y, 1);
		tempGeometry.translate(boxHelper.min.x, boxHelper.min.y, 0);
		tempGeometry.rotateX(Math.PI / 2);

		setGeometry(tempGeometry);
	}, [points]);

	const p = new Vector3();
	const q = new Quaternion();
	const s = new Vector3();
	t.decompose(p, q, s);
	if (colorWrite) {
		p.add(new Vector3(0, 0.01, 0).applyQuaternion(q));
	}

	return geometry && showRoom ? (
		<RigidBody type="fixed">
			<mesh
				renderOrder={colorWrite ? 2 : 1}
				geometry={geometry}
				position={p}
				quaternion={q}
				scale={s}
			>
				<shaderMaterial
					attach="material"
					args={[holodeckShader]}
					depthWrite={true}
					colorWrite={colorWrite}
				/>
			</mesh>
		</RigidBody>
	) : null;
};

export const Box = ({
	points,
	transform,
	colorWrite = false,
}: PlaneInfo & { colorWrite: boolean }) => {
	const [geometry, setGeometry] = useState<BoxGeometry | null>(null);
	const [t] = useState<Matrix4>(new Matrix4().fromArray(transform));
	const { showRoom } = useSettingsStore();
	useEffect(() => {
		if (points.length > 0) {
			const width = 2 * points[0][0];
			const depth = 2 * points[0][1];
			const height = transform[13]; // Assuming transform[13] is the y-coordinate
			const boxGeometry = new BoxGeometry(width, height, depth);
			setGeometry(boxGeometry);
		}
	}, [points, transform]);

	const p = new Vector3();
	const q = new Quaternion();
	const s = new Vector3();
	t.decompose(p, q, s);
	p.y = 0.5 * p.y;

	return geometry && showRoom ? (
		<RigidBody type="fixed">
			<mesh
				renderOrder={colorWrite ? 2 : 1}
				geometry={geometry}
				position={p}
				quaternion={q}
				scale={s}
			>
				<shaderMaterial
					attach="material"
					side={0}
					args={[holodeckShader]}
					depthWrite={true}
					colorWrite={colorWrite}
				/>
			</mesh>
		</RigidBody>
	) : null;
};
