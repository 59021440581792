import type { RigidBodyTypeString } from "@react-three/rapier";
import { me } from "playroomkit";
import { useState } from "react";
import { HandleType } from "../engine/HandleType";
import { availableItems } from "../engine/availableItems";
import { useAddSceneItem } from "../engine/useAddSceneItem";

export const PankacakeSpawnerMenu = () => {
	const addSceneItem = useAddSceneItem();
	const [physical, setPhysical] = useState<RigidBodyTypeString | undefined>(
		undefined,
	);
	const [handleType, setHandleType] = useState<HandleType>(HandleType.NONE);

	return (
		<div>
			<div style={{ margin: "10px 0" }}>
				<select
					id="itemSelector"
					style={{
						padding: "10px 20px",
						borderRadius: "5px",
						border: "1px solid lightgray",
					}}
					defaultValue={"ballCannon"}
				>
					{Object.keys(availableItems).map((key) => (
						<option key={key} value={key}>
							{key}
						</option>
					))}
				</select>
				<div style={{ margin: "10px 0" }}>
					<label>
						<input
							type="radio"
							name="itemType"
							value={undefined}
							checked={physical === undefined}
							onChange={() => setPhysical(undefined)}
						/>
						No Physics
					</label>
					<label>
						<input
							type="radio"
							name="itemType"
							value="fixed"
							checked={physical === "fixed"}
							onChange={() => setPhysical("fixed")}
						/>
						Fixed
					</label>
					<label>
						<input
							type="radio"
							name="itemType"
							value="dynamic"
							checked={physical === "dynamic"}
							onChange={() => setPhysical("dynamic")}
						/>
						Dynamic
					</label>
				</div>
				<div style={{ margin: "10px 0" }}>
					{[
						HandleType.NONE,
						HandleType.SMALL,
						HandleType.FULL,
						HandleType.SELF,
					].map((handle) => (
						<label key={handle}>
							<input
								type="radio"
								name="handleType"
								value={handle}
								checked={handleType === handle}
								onChange={() => setHandleType(handle)}
							/>
							{HandleType[handle]}
						</label>
					))}
				</div>
				<button
					onClick={() => {
						const selectedItem = document.getElementById(
							"itemSelector",
						) as HTMLSelectElement;
						addSceneItem(
							selectedItem.value,
							{},
							{
								position: [0, 1, 0],
								handleType,
								velocities: {
									linear: { x: 2, y: 1, z: 0 },
									angular: { x: 0, y: 0, z: 0 },
								},
								physical,
							},
						);
					}}
					type="button"
					style={{
						padding: "10px 20px",
						borderRadius: "5px",
						background: "lightblue",
						color: "white",
						border: "none",
						marginLeft: "10px",
					}}
				>
					Spawn Item
				</button>
				<button
					onClick={() => {
						addSceneItem(
							"mainMenu",
							{},
							{
								position: [0, 1, 0],
								handleType: HandleType.SMALL,
							},
						);
					}}
					type="button"
					style={{
						padding: "10px 20px",
						borderRadius: "5px",
						background: "lightblue",
						color: "white",
						border: "none",
						marginLeft: "10px",
					}}
				>
					Main Menu
				</button>
				<button
					onClick={() => {
						addSceneItem(
							"avatar",
							{},
							{
								position: [0, 0, 0],
								overrideId: `${me().id}-avatar`,
								handleType: HandleType.SMALL,
								grabbedBy: me().id,
								avatarState: {},
							},
						);
					}}
					type="button"
					style={{
						padding: "10px 20px",
						borderRadius: "5px",
						background: "lightblue",
						color: "white",
						border: "none",
						marginLeft: "10px",
					}}
				>
					Avatar
				</button>
				<button
					onClick={() => {
						addSceneItem(
							"hive",
							{},
							{
								position: [0, 0, 0],
								handleType: HandleType.SMALL,
								loaded: 0,
							},
						);
						addSceneItem(
							"resource",
							{},
							{
								position: [2, 0.5, 0],
								handleType: HandleType.SMALL,
								loaded: 0,
							},
						);
					}}
					type="button"
					style={{
						padding: "10px 20px",
						borderRadius: "5px",
						background: "lightblue",
						color: "white",
						border: "none",
						marginLeft: "10px",
					}}
				>
					WorkerBees
				</button>
			</div>
		</div>
	);
};
