import { create } from "zustand";

export interface SettingsStore {
	showOrigins: boolean;
	toggleShowOrigins: () => void;
	showRoom: boolean;
	toggleShowRoom: () => void;
}

export const useSettingsStore = create<SettingsStore>((set) => ({
	showOrigins: false,
	toggleShowOrigins: () =>
		set((state) => ({ showOrigins: !state.showOrigins })),
	showRoom: true,
	toggleShowRoom: () => set((state) => ({ showRoom: !state.showRoom })),
}));
