import {
	GrabHand,
	PointerHand,
	TeleportHand,
	TouchHand,
	type HandType,
} from "@coconut-xr/natuerlich/defaults";
import { useHandPoses, useInputSources } from "@coconut-xr/natuerlich/react";
import { useState } from "react";

export const SwitchHands = () => {
	const inputSources = useInputSources();

	return (
		<>
			{inputSources
				.filter((inputSource) => inputSource.hand != null)
				.map((inputSource) => (
					<Hand
						hand={inputSource.hand as XRHand} //we filtered for inputSource with hands so we can be sure here
						id={getInputSourceId(inputSource)}
						inputSource={inputSource}
						key={getInputSourceId(inputSource)}
						// {...props}
					/>
				))}
		</>
	);
};

export interface HandProps {
	hand: XRHand;
	id: number;
	inputSource: XRInputSource;
}

export const Hand = (props: HandProps) => {
	const [type, setType] = useState<HandType>("touch");
	useHandPoses(
		props.hand,
		props.inputSource.handedness,
		(name, prevName) => {
			if (name === "fist" && prevName === "horns") {
				setType("grab");
			}
			if (name === "point" && prevName === "horns") {
				setType("pointer");
			}
			if (name === "relax" && prevName === "thumb") {
				setType("touch");
			}
		},
		{
			fist: "fist.handpose",
			relax: "relax.handpose",
			point: "point.handpose",
			victory: "peace.handpose",
			horns: "horns.handpose",
			thumb: "thumb.handpose",
		},
	);
	switch (type) {
		case "grab":
			return <GrabHand {...props} />;
		case "pointer":
			return <PointerHand {...props} />;
		case "teleport":
			return <TeleportHand {...props} />;
		case "touch":
			return <TouchHand {...props} cursorVisible={false} />;
	}
};
const xrInputSourceIdMap = new Map<XRInputSource, number>();
export function getInputSourceId(inputSource: XRInputSource): number {
	let id = xrInputSourceIdMap.get(inputSource);
	if (id == null) {
		id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
		xrInputSourceIdMap.set(inputSource, id);
	}
	return id;
}
