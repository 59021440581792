import { Matrix4 } from "three";
import { create } from "zustand";

export interface AnchorTransformState {
	transform: Matrix4;
	inverseTransform: Matrix4;
	setTransform: (transform: Matrix4 | number[]) => void;
	getMatrix4: () => Matrix4;
}

export const useAnchorTransform = create<AnchorTransformState>((set, get) => ({
	transform: new Matrix4().identity(),
	inverseTransform: new Matrix4().identity(),
	setTransform: (transform: Matrix4 | number[]) =>
		set(
			transform instanceof Matrix4
				? {
						transform,
						inverseTransform: transform.clone().invert(),
					}
				: {
						transform: new Matrix4().fromArray(transform),
						inverseTransform: new Matrix4().fromArray(transform).invert(),
					},
		),
	getMatrix4: () => {
		const { transform } = get();
		return transform;
	},
}));
