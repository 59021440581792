import { useInputSources } from "@coconut-xr/natuerlich/react";
import { useFrame, type RootState } from "@react-three/fiber";
import { me } from "playroomkit";
import { Matrix4, Vector3 } from "three";
import { useAnchorTransform } from "../AnchorTransform";
import type { CompressedEntity } from "../compressEntity";
import { ECS, FORCE_UPDATE } from "./state";

/**
 * dirty items need to be streamed to other players
 */
const allAvatars = ECS.world.with("transform", "avatarState");

export type RealtimeUpdate = {
	[key: string]: CompressedEntity;
};

export const updateIntervalInMs = 1000;
const MatrixHelper = new Matrix4();
const ONE = new Vector3(1, 1, 1);
export const AvatarSystem = () => {
	const sources = useInputSources();
	const { inverseTransform: inverseAnchor } = useAnchorTransform();
	useFrame((state, dt, frame: XRFrame) => {
		const myId = me().id;
		for (const avatar of allAvatars) {
			if (avatar.id === `${myId}-avatar`) {
				if (avatar.child) {
					avatar.child.visible = false;
				}
				if (avatar.handle) {
					avatar.handle.visible = false;
				}
				if (frame) {
					avatar.avatarState = parseAvatarDataFromFrame(
						state,
						frame,
						sources,
						inverseAnchor,
					);
				} else {
					MatrixHelper.compose(
						state.camera.position,
						state.camera.quaternion,
						ONE,
					);
					avatar.avatarState = {
						gaze: new Float32Array(MatrixHelper.toArray()),
					};
				}
				avatar.lastSync = FORCE_UPDATE;
			} else {
				if (!avatar.avatarState) {
					continue;
				}
				if (avatar.avatarState.gaze) {
					const gaze = MatrixHelper.fromArray(
						new Float32Array(avatar.avatarState.gaze),
					);
					const avatarObject = avatar.child?.getObjectByName("Head");
					if (avatarObject) {
						// console.log("Gaze", gaze.toArray());
						avatarObject.visible = true;
						avatarObject.matrix.copy(gaze);
						gaze.decompose(
							avatarObject.position,
							avatarObject.quaternion,
							avatarObject.scale,
						);
						// console.log(
						// 	"updating gaze",
						// 	avatar.id,
						// 	avatarObject.position.toArray(),
						// );
					}
				}
				for (const [hand, pose] of Object.entries({
					Left: avatar.avatarState.leftHandPose,
					Right: avatar.avatarState.rightHandPose,
				})) {
					if (pose) {
						const pose2 =
							pose instanceof Float32Array ? pose : new Float32Array(pose);
						if (!(pose2 instanceof Float32Array)) {
							console.error("Invalid pose data", pose2);
							continue;
						}
						const handObject = avatar.child?.getObjectByName(`${hand}`);
						if (!handObject) {
							continue;
						}
						for (let i = 0; i < 25; i++) {
							const matrix = MatrixHelper.fromArray(
								pose2.slice(i * 16, (i + 1) * 16),
							);
							const bone = handObject?.getObjectByName(`${AvatarJoints[i]}`);
							if (bone) {
								matrix.decompose(bone.position, bone.quaternion, bone.scale);
								// bone.position.z += 0.2;
							} else {
								// console.log(`Bone not found: Left${ReadyPlayerMeJoints[i]}`, i);
							}
						}
						// console.log(pose);
						// avatar.avatarState.leftHandPose = pose;
					} else {
						const handObject = avatar.child?.getObjectByName(`${hand}`);
						if (!handObject) {
							continue;
						}
						handObject.visible = false;
					}
				}
			}
		}
	});

	return null;
};

export interface AvatarState {
	leftHandPose?: Float32Array;
	rightHandPose?: Float32Array;
	gaze?: Float32Array;
}
export function parseAvatarDataFromFrame(
	state: RootState,
	frame: XRFrame,
	sources: XRInputSource[],
	inverseAnchor: Matrix4,
): AvatarState {
	const referenceSpace = state.gl.xr.getReferenceSpace();
	const result: AvatarState = {};
	if (referenceSpace) {
		const viewerPose = frame.getViewerPose(referenceSpace);
		if (viewerPose) {
			const viewerTransform = MatrixHelper.fromArray(
				viewerPose.transform.matrix,
			);
			const gazeTransform = viewerTransform.premultiply(inverseAnchor);
			result.gaze = new Float32Array(gazeTransform.toArray());
		}
	}

	for (const source of sources) {
		if (source.hand && frame.getJointPose && referenceSpace) {
			const pose = new Float32Array(16 * 26);
			source.hand.forEach((jointSpace, joint) => {
				if (frame.getJointPose) {
					const jointPose = frame.getJointPose(jointSpace, referenceSpace);
					if (!jointPose) {
						return;
					}
					const joinIndex = AvatarJoints.indexOf(joint);
					const jointTransform = MatrixHelper.fromArray(
						jointPose.transform.matrix,
					);
					const jointSpaceMatrix = jointTransform.premultiply(inverseAnchor);
					pose.set(jointSpaceMatrix.toArray(), joinIndex * 16);
				}
			});
			if (source.gripSpace) {
				const gripPose = frame.getPose(source.gripSpace, referenceSpace);
				if (gripPose) {
					const gripTransform = MatrixHelper.fromArray(
						gripPose.transform.matrix,
					);
					const gripSpace = gripTransform.premultiply(inverseAnchor);
					if (source.handedness === "right") {
						pose.set(gripSpace.toArray(), 16 * 25);
					} else {
						pose.set(gripSpace.toArray(), 16 * 25);
					}
				}
			}

			if (source.handedness === "left") {
				result.leftHandPose = pose;
			} else if (source.handedness === "right") {
				result.rightHandPose = pose;
			}
		}
	}
	return result;
}

export const AvatarJoints = [
	"wrist",
	"thumb-metacarpal",
	"thumb-phalanx-proximal",
	"thumb-phalanx-distal",
	"thumb-tip",
	"index-finger-metacarpal",
	"index-finger-phalanx-proximal",
	"index-finger-phalanx-intermediate",
	"index-finger-phalanx-distal",
	"index-finger-tip",
	"middle-finger-metacarpal",
	"middle-finger-phalanx-proximal",
	"middle-finger-phalanx-intermediate",
	"middle-finger-phalanx-distal",
	"middle-finger-tip",
	"ring-finger-metacarpal",
	"ring-finger-phalanx-proximal",
	"ring-finger-phalanx-intermediate",
	"ring-finger-phalanx-distal",
	"ring-finger-tip",
	"pinky-finger-metacarpal",
	"pinky-finger-phalanx-proximal",
	"pinky-finger-phalanx-intermediate",
	"pinky-finger-phalanx-distal",
	"pinky-finger-tip",
];

export const ReadyPlayerMeJoints = [
	"HandUndefined",
	"xHandThumb1",
	"xHandThumb2",
	"xHandThumb3",
	"xHandThumb4",
	"xHandIndex_Undefined",
	"xHandIndex1",
	"xHandIndex2",
	"xHandIndex3",
	"xHandIndex4",
	"xHandMiddle_Undefined",
	"xHandMiddle1",
	"xHandMiddle2",
	"xHandMiddle3",
	"xHandMiddle4",
	"xHandRing_Undefined",
	"xHandRing1",
	"xHandRing2",
	"xHandRing3",
	"xHandRing4",
	"HandPinky_Undefined",
	"HandPinky1",
	"HandPinky2",
	"HandPinky3",
	"HandPinky4",
];
