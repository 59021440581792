import { Controllers, XRCanvas } from "@coconut-xr/natuerlich/defaults";
import {
	ImmersiveSessionOrigin,
	NonImmersiveCamera,
	SessionModeGuard,
	usePersistedAnchor,
	useXR,
} from "@coconut-xr/natuerlich/react";
import { RoomContext } from "@livekit/components-react";
import { NextUIProvider } from "@nextui-org/react";
import { OrbitControls } from "@react-three/drei";
import isMobile from "is-mobile";
import {
	Room,
	RoomEvent,
	Track,
	type RemoteParticipant,
	type RemoteTrack,
	type RemoteTrackPublication,
} from "livekit-client";
import { insertCoin, me } from "playroomkit";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { HUD } from "./HUD";
import { useAnchorTransform } from "./engine/AnchorTransform";
import { CrossHair } from "./engine/Crosshair";
import { MainScene } from "./engine/MainScene";
import { SwitchHands } from "./engine/SwitchHands";
import { ColoLocatorTool, useColocatorState } from "./headset/colocatorTool";
import { HeadSetMenu } from "./headset/headSetMenu";
import { MobileView } from "./pancake/MobileView";
import { PancakeMenu } from "./pancake/pankakeMenu";

import "./styles/main.css";
const room = new Room({});

room.on(RoomEvent.TrackSubscribed, handleTrackSubscribed);

function handleTrackSubscribed(
	track: RemoteTrack,
	publication: RemoteTrackPublication,
	participant: RemoteParticipant,
) {
	const element = track.attach();
	if (track.kind === Track.Kind.Audio) {
		document.body.appendChild(element);
	}
}

const isOculusQuest = () => {
	const userAgent = navigator.userAgent;
	// This is a simplistic check. You might need to refine it based on the actual userAgent string of Quest 3
	return /Oculus|Android/.test(userAgent);
};
function Main() {
	const [connected, setConnected] = React.useState(false);
	useEffect(() => {
		insertCoin({
			roomCode: localStorage.getItem("roomCode") ?? undefined,
			skipLobby: true,
		}).then(() => {
			setConnected(true);
			window.document.title = me().id;
		});
		room.on("connected", () => {
			room.localParticipant.setMicrophoneEnabled(false);
			room.localParticipant.setCameraEnabled(false);
		});
	}, []);

	const { enabled: colocatorEnabled } = useColocatorState();
	const [persistentAnchor, setPersistentAnchor] = usePersistedAnchor("origin");
	const { transform, setTransform } = useAnchorTransform();

	const xrState = useXR();

	useEffect(() => {
		xrState.onNextFrameCallbacks?.add((state, delta, frame) => {
			const referenceSpace = state.gl.xr.getReferenceSpace();

			if (persistentAnchor && frame && referenceSpace) {
				const pose = frame.getPose(
					persistentAnchor.anchorSpace,
					referenceSpace,
				);
				if (pose) {
					setTransform(Array.from(pose.transform.matrix));
				}
			}
		});
	}, [persistentAnchor, setTransform, xrState]);
	if (isMobile()) {
		return <MobileView />;
	}
	return (
		<RoomContext.Provider value={room}>
			<div className="main">
				{isOculusQuest() ? <HeadSetMenu /> : <PancakeMenu />}
				<XRCanvas>
					<SessionModeGuard allow={["immersive-ar", "none"]}>
						<CrossHair colors={["yellow", "orange", "red"]} />

						{connected ? (
							<>
								<mesh matrix={transform} matrixAutoUpdate={false}>
									<CrossHair colors={["red", "green", "blue"]} forceVisible />
									{connected ? <MainScene /> : null}
								</mesh>
								{/* <ErrorBoundary>
									<PlayerStreamer />
								</ErrorBoundary> */}
							</>
						) : null}
					</SessionModeGuard>
					{colocatorEnabled ? (
						<ColoLocatorTool onAnchorSet={setPersistentAnchor} />
					) : (
						<>
							<NonImmersiveCamera position={[0, 1.5, 4]} near={0.1} far={100} />
							{/* <Avatar /> */}
							<ImmersiveSessionOrigin
								name="ImmersiveSessionOrigin"
								// position={position}
								// rotation={[0, angle, 0]}
								cameraContent={<HUD />}
							>
								<SwitchHands />
								<Controllers type="pointer" />
							</ImmersiveSessionOrigin>
							<OrbitControls makeDefault />
						</>
					)}
				</XRCanvas>
			</div>
		</RoomContext.Provider>
	);
}

const container = document.getElementById("root");
if (!container) {
	throw new Error("Root element not found");
}
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<NextUIProvider>
			<Main />
		</NextUIProvider>
	</React.StrictMode>,
);
