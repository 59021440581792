import { Button, TextInput } from "@coconut-xr/apfel-kruemel";
import {
	Container,
	DefaultStyleProvider,
	Image,
	Text,
} from "@coconut-xr/koestlich";
import { useMultiplayerState } from "playroomkit";
import { useState } from "react";
import type { SketchFabItemProps } from "./SketchFabItem";
import { downloadSketchFabModel } from "./downloadSketchFabModel";
import { useAddSceneItem } from "./engine/useAddSceneItem";

export interface SketchFabResult {
	uri: string;
	thumbnails: {
		images: {
			url: string;
		}[];
	};
	isDownloadable: boolean;
	archives: {
		gltf: {
			faceCount: number;
		};
	};
}

export interface SketchFabSearchResponse {
	results: SketchFabResult[];
}

export const SketchFabSpawner = () => {
	const [searchTerm, setSearchTerm] = useMultiplayerState("searchTerm", "");
	const [results, setResults] = useState<SketchFabResult[]>([]);
	const addSceneItem = useAddSceneItem();


	const handleSearch = async (searchTerm: string) => {
		const response = await fetch(
			`https://api.sketchfab.com/v3/search?type=models&q=${searchTerm}`,
		);
		const data = (await response.json()) as SketchFabSearchResponse;
		const acceptableResults = data.results.filter(
			(x) => x.isDownloadable && x.archives.gltf.faceCount < 50000,
		);
		setResults(acceptableResults);
	};

	window.search = (newTerm: string) => {
		setSearchTerm(newTerm);
	};


	return (
		<>
			<DefaultStyleProvider color="white">
				<Container gapRow={16} width={300}>
					<TextInput
						value={searchTerm ?? ""}
						onValueChange={setSearchTerm}
						borderColor={"yellow"}
						style="rect"
					/>
					<Button
						disabled={!searchTerm}
						onClick={() => handleSearch(searchTerm as string)}
					>
						<Text index={1} marginTop={8}>
							Search
						</Text>
					</Button>
				</Container>
				<Container
					flexShrink={1}
					flexDirection="row"
					overflow="scroll"
					paddingBottom={16}
				>
					{results.map((result: SketchFabResult) => (
						<Container>
							<Image
								onClick={(e) => {
									console.log("clicked", result);
									addSceneItem<SketchFabItemProps>(
										"sketchfab",
										{
											url: result.uri,
										},
										{
											scale: [0.001, 0.001, 0.001],
										},
									);
									downloadSketchFabModel(result.uri);
									e.stopPropagation();
									e.preventDefault();
								}}
								url={result.thumbnails.images[0].url}
								width={200}
								borderRadius={8}
								index={0}
							/>
						</Container>
					))}
				</Container>
			</DefaultStyleProvider>
		</>
	);
};
