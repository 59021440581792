import Peer, { type DataConnection } from "peerjs";
import { me, useIsHost, usePlayerState, usePlayersList } from "playroomkit";
import { useCallback, useEffect, useRef, useState } from "react";
import type { RealtimeUpdate } from "./MultiplayerSystem";

const connectionsById = new Map<string, DataConnection>();
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
(globalThis as any).connectionsById = connectionsById; // Consider removing or replacing 'any' with a more specific type if possible.

export enum ReceiveMode {
	ALL = 0,
	HOST = 1,
	OTHERS = 2,
}

export interface PeerCommunication {
	send: <T>(data: T, mode: ReceiveMode) => void;
	getUpdates: () => RealtimeUpdate[];
}
export function playroomId2peerjsId(str: string): string {
	return str.replace(/[^a-zA-Z0-9]/g, "");
}

export const usePeerCommunication = (): PeerCommunication => {
	const isHost = useIsHost();
	const players = usePlayersList(true);
	const [, setPeerId] = usePlayerState(me(), "peerId", "");
	const [myPeer, setMyPeer] = useState<Peer | null>(null);
	const receivedUpdates = useRef<RealtimeUpdate[]>([]);

	useEffect(() => {
		const peer = new Peer();
		//.id), {
		// host: document.location.hostname,
		// port: Number(document.location.port),
		// secure: document.location.protocol === "https:",
		// path: "/peerjs",
		//});
		peer.on("open", (id) => {
			//console.log("PeerConnection opened", id);
			console.log("my peerid", id);
			setPeerId(peer.id, true);
			setMyPeer(peer);
		});
		peer.on("error", (error) => {
			console.error("peer error", error);
		});
		peer.on("close", () => {
			//console.log("peerconnection closed");
			setMyPeer(null);
		});
		peer.on("connection", (connection): void => {
			//console.log("established connection from", connection.peer);
			connectionsById.set(connection.peer, connection);
			connection.on("close", () => {
				//console.log("connection closed", connection.peer);
				connectionsById.delete(connection.peer);
			});
			connection.on("data", (data) => {
				receivedUpdates.current.push(data as RealtimeUpdate);
			});
		});
		return () => {
			peer.destroy();
		};
	}, []);

	const connectToPlayers = useCallback(() => {
		if (!myPeer) {
			//console.log("myPeer is null");
			return;
		}
		//console.log("checking connections to players");
		// console.log("players", players);
		for (const player of players) {
			const myPeerId = myPeer.id;
			const remotePeerId = player.getState("peerId");
			if (!remotePeerId) {
				//console.log("no peerId for player", player);
				continue;
			}
			// "smaller" peerId is the one that is being connected to
			if (remotePeerId < myPeerId) {
				//console.log("skipping smaller peerId");
				continue;
			}
			if (player === me()) {
				//console.log("skipping myself");
				continue;
			}
			if (connectionsById.has(remotePeerId)) {
				const existingConnection = connectionsById.get(remotePeerId);
				//console.log("existing connection", existingConnection);
				continue;
			}
			//console.log("connecting to", remotePeerId);
			const connection = myPeer.connect(remotePeerId);
			connection.on("open", () => {
				//console.log("sucesssfully connected to", remotePeerId);
				connectionsById.set(remotePeerId, connection);
			});
			connection.on("data", (data) => {
				receivedUpdates.current.push(data as RealtimeUpdate);
			});
			connection.on("error", (error) => {
				console.error("error", error);
			});
		}
		for (const connectionId of connectionsById.keys()) {
			if (!players.some((p) => p.getState("peerId") === connectionId)) {
				connectionsById.get(connectionId)?.close();
				connectionsById.delete(connectionId);
			}
		}
	}, [players, myPeer]); // Updated dependencies to include 'players' instead of 'players.length'

	useEffect(() => {
		if (!myPeer) {
			return;
		}
		connectToPlayers();
		const interval = setInterval(() => {
			connectToPlayers();
		}, 10000);
		return () => {
			clearInterval(interval);
		};
	}, [connectToPlayers, myPeer]);

	const send = useCallback(
		<T>(data: T, mode: ReceiveMode): void => {
			if (mode === ReceiveMode.ALL) {
				for (const connection of connectionsById.values()) {
					if (!connection) {
						connectionsById.delete(connection);
						continue;
					}
					if (!connection.open) {
						//// console.log("connection not open", connection.peer);
						continue;
					}
					connection.send(data);
				}
			} else if (mode === ReceiveMode.HOST) {
				// const hostConnection = connectionsById.get(
				// 	players.find((p) => p.isHost)?.id,
				// );
				// if (!hostConnection) {
				//// 	// console.log("no host connection");
				// 	return;
				// }
				// hostConnection.send(data);
			} else if (mode === ReceiveMode.OTHERS) {
				for (const player of players) {
					if (player === me()) {
						continue;
					}
					const connection = connectionsById.get(player.getState("peerId"));
					if (!connection) {
						//// console.log("no connection to", player.id);
						continue;
					}
					if (!connection.open) {
						console.warn("connection not open", connection.peer);
						continue;
					}
					connection.send(data);
				}
			}
		},
		[players],
	);

	return {
		send,
		getUpdates: () => {
			const updates = receivedUpdates.current;
			receivedUpdates.current = [];
			return updates;
		},
	};
};
