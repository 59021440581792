import { Button, Glass } from "@coconut-xr/apfel-kruemel";
import { Container, Text } from "@coconut-xr/koestlich";
import {
	Controllers,
	KoestlichQuadLayer,
} from "@coconut-xr/natuerlich/defaults";
import {
	ImmersiveSessionOrigin,
	NonImmersiveCamera,
} from "@coconut-xr/natuerlich/react";
import { createPortal, useThree } from "@react-three/fiber";
import { Quaternion, Vector3 } from "three";
import { create } from "zustand";
import { CrossHair } from "../engine/Crosshair";

export interface ColocatorState {
	enabled: boolean;
	groundOk: boolean;
	firstPoint: Vector3 | null;
	enable(): void;
	disable(): void;
	setFirstPoint(point: Vector3 | null): void;
	confirmGround(): void;
}

export const useColocatorState = create<ColocatorState>((set) => ({
	enabled: false,
	firstPoint: null,
	groundOk: false,
	enable: () =>
		set((state) => ({
			...state,
			enabled: true,
			firstPoint: null,
			groundOk: false,
		})),
	disable: () =>
		set((state) => ({
			...state,
			enabled: false,
			firstPoint: null,
			groundOk: false,
		})),
	setFirstPoint: (point) => set((state) => ({ ...state, firstPoint: point })),
	confirmGround: () => set((state) => ({ ...state, groundOk: true })),
}));

export const ColoLocatorTool = ({
	onAnchorSet,
}: { onAnchorSet: (point: Vector3, rotation: Quaternion) => void }) => {
	const camera = useThree((state) => state.camera);
	const {
		enabled,
		firstPoint,
		groundOk,
		confirmGround,
		disable,
		setFirstPoint,
	} = useColocatorState();
	return enabled ? (
		<>
			{firstPoint && (
				<mesh position={firstPoint} scale={0.1}>
					<CrossHair colors={["gray", "white", "black"]} forceVisible />
				</mesh>
			)}

			<NonImmersiveCamera position={[0, 1.5, 4]} />
			<ImmersiveSessionOrigin>
				<CrossHair colors={["gray", "white", "black"]} scale={[5, 2, 5]} />

				<Controllers
					type="pointer"
					onPointerDownMissed={(event) => {
						if (firstPoint) {
							const midPoint = new Vector3()
								.addVectors(firstPoint, event.point)
								.divideScalar(2);
							midPoint.y = 0;
							const angle = Math.atan2(
								event.point.x - firstPoint.x,
								event.point.z - firstPoint.z,
							);
							onAnchorSet(
								midPoint,
								new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), angle),
							);
							disable();
						} else {
							setFirstPoint(event.point);
						}
						// createAnchor(event.point, new Quaternion())
					}}
				/>
			</ImmersiveSessionOrigin>
			{createPortal(
				<KoestlichQuadLayer
					pixelWidth={800}
					pixelHeight={400}
					position={[0, 0, -1]}
					scale={[0.4, 0.2, 0.1]}
				>
					<Container>
						<Glass
							height={"100%"}
							padding={60}
							justifyContent="space-between"
							alignItems="center"
						>
							<Text color="white" fontSize={60}>
								Calibrate Colocation
							</Text>
							{!groundOk ? (
								<>
									<Text color="white" fontSize={30}>
										Check if the black and white crosshair sits on the ground.
										If not, hold the oculus button to reset. If stil not OK you
										need to fix it outside of this app.
									</Text>
									<Button onClick={confirmGround} backgroundColor="gray">
										<Text color="white" fontSize={30}>
											Ground is OK
										</Text>
									</Button>
								</>
							) : firstPoint ? (
								<>
									<Text color="white" fontSize={30}>
										Select second point
									</Text>
									<Button
										onClick={() => setFirstPoint(null)}
										backgroundColor="gray"
									>
										<Text color="white" fontSize={30}>
											Reset first point
										</Text>
									</Button>
								</>
							) : (
								<Text color="white" fontSize={30}>
									All headsets in the same room need to calibrate the same two
									points in the real world. Select first point now by touching
									it with the controller and pressing trigger.
								</Text>
							)}
						</Glass>
					</Container>
				</KoestlichQuadLayer>,
				camera,
			)}
		</>
	) : null;
};
