import {
	ActivityIndicator,
	IconButton,
	Slider,
} from "@coconut-xr/apfel-kruemel";
import { Container, DefaultStyleProvider, Text } from "@coconut-xr/koestlich";
import {
	Camera,
	CameraOff,
	Mic,
	MicOff,
	PlayCircle,
	StopCircle,
} from "@coconut-xr/lucide-koestlich";
import {
	useConnectionState,
	useEnsureRoom,
	useLocalParticipant,
	useRemoteParticipants,
	useTrackToggle,
} from "@livekit/components-react";
import { ConnectionState, Track } from "livekit-client";
import { useAddSceneItem } from "../../engine/useAddSceneItem";
import { MuteParticipantButton } from "./MuteParticipantButton";
import type { VideoTileProps } from "./ScreenshareTile";

export const LivekitControls = () => {
	// const players = usePlayersList();
	// const [scene] = useMultiplayerState("scene", []);
	const lkParticipants = useRemoteParticipants();
	const room = useEnsureRoom();
	const connectionState = useConnectionState();
	const localParticipant = useLocalParticipant();
	const addSceneItem = useAddSceneItem();
	const {
		enabled: micEnabled,
		pending: micPending,
		toggle: micToggle,
	} = useTrackToggle({
		source: Track.Source.Microphone,
	});
	const {
		enabled: camEnabled,
		pending: camPending,
		toggle: camToggle,
	} = useTrackToggle({
		source: Track.Source.ScreenShare,
	});

	return (
		<Container key="livekit-menu">
			<DefaultStyleProvider color="white">
				<Text fontSize={30}>Livekit Controls</Text>
				<Container flexDirection="row" gapColumn={10}>
					<IconButton
						size="xl"
						platter
						onClick={() => {
							const wsURL = "wss://playground-d8j6xe0w.livekit.cloud";

							if (connectionState === ConnectionState.Connected) {
								room.disconnect();
							} else {
								fetch(
									"https://xrsandbox0r4a8b1i-livekit.functions.fnc.fr-par.scw.cloud/",
								)
									.then((response) => response.json())
									.then((data) => {
										const token = data.jwt;
										if (token) {
											room.connect(wsURL, token);
										}
									});
							}
						}}
					>
						{connectionState === ConnectionState.Connected ? (
							<StopCircle height={20} width={20} />
						) : connectionState === ConnectionState.Connecting ||
							connectionState === ConnectionState.Reconnecting ? (
							<ActivityIndicator size="sm" />
						) : (
							<PlayCircle height={20} width={20} />
						)}
					</IconButton>
					<IconButton
						size="xl"
						platter
						onClick={() => {
							micToggle();
						}}
					>
						{micPending ? (
							<ActivityIndicator size="sm" />
						) : micEnabled ? (
							<Mic height={20} width={20} />
						) : (
							<MicOff height={20} width={20} />
						)}
					</IconButton>
					<IconButton
						size="xl"
						platter
						onClick={() => {
							camToggle();
						}}
					>
						{camPending ? (
							<ActivityIndicator size="sm" />
						) : camEnabled ? (
							<Camera height={20} width={20} />
						) : (
							<CameraOff height={20} width={20} />
						)}
					</IconButton>
				</Container>
				<Container gapRow={10}>
					{lkParticipants.map((participant) => {
						return (
							<Container
								key={participant.identity}
								flexGrow={1}
								flexDirection="row"
								gapColumn={10}
							>
								<Container flexGrow={1} flexDirection="row" alignItems="center">
									<Text fontSize={20}>{participant.identity}</Text>
								</Container>
								<Slider size="md" width={100} />
								<IconButton
									size="xl"
									platter
									onClick={(e) => {
										addSceneItem<VideoTileProps>(
											"videoTile",
											{
												participantName: participant.name ?? "",
												participantIdentity: participant.identity,
												sourceType: Track.Source.ScreenShare,
											},
											{
												position: [0, 1, 0],
											},
										);
									}}
								>
									{camPending ? (
										<ActivityIndicator size="sm" />
									) : camEnabled ? (
										<Camera height={20} width={20} />
									) : (
										<CameraOff height={20} width={20} />
									)}
								</IconButton>
								<MuteParticipantButton participant={participant} />
							</Container>
						);
					})}
				</Container>
			</DefaultStyleProvider>
		</Container>
	);
};
