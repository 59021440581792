import { useEnterXR } from "@coconut-xr/natuerlich/react";
import { defaultScene } from "../engine/DefaultScene";
import { useRestoreScene } from "../engine/useRestoreScene";
import { PankacakeSpawnerMenu } from "../pancake/PSpawnerMenu";
import { PankacakeSceneTree } from "../pancake/SceneTree";
const sessionOptions: XRSessionInit = {
	requiredFeatures: [
		"local-floor",
		"hand-tracking",
		"plane-detection",
		"anchors",
		"layers",
	],
};

export const HeadSetMenu = () => {
	const enterAR = useEnterXR("immersive-ar", sessionOptions);
	const restoreSzene = useRestoreScene();
	return (
		<div>
			<button onClick={enterAR} style={{ fontSize: 64 }} type="button">
				Enter AR
			</button>
			<button
				onClick={() => restoreSzene(defaultScene)}
				style={{ fontSize: 64 }}
				type="button"
			>
				Reset Szene
			</button>
			<PankacakeSceneTree />
			<PankacakeSpawnerMenu />
		</div>
	);
};
