import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import type { PersistableScene } from "./PersistableScene";

export interface PersistedScenes {
    [key: string]: PersistableScene;
}

export interface PersistedSceneStore {
    scenes: PersistedScenes;
    saveScene: (sceneId: string, scene: PersistableScene) => void;
    removeScene: (sceneId: string) => void;
    clearScenes: () => void;
}


export const useSavedScenes = create<PersistedSceneStore, [["zustand/persist", { storage: Storage }]]>(
    persist(
    (set) => {
    return {
        scenes: {},
        saveScene: (sceneId, scene) => {
            console.log("Saving scene", sceneId, scene);
            set((state) => ({ scenes: { ...state.scenes, [sceneId]: scene } }));
        },
        removeScene: (sceneId) => {
            set((state) => {
                const scenes = { ...state.scenes };
                delete scenes[sceneId];
                return { scenes };
            });
        },
        clearScenes: () => {
            set({ scenes: {} });
        },
    };
    },
    {
        name: "saved-scenes",
        storage: createJSONStorage(() => sessionStorage),
    }
    ),
);


