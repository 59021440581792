import { Button, IconButton } from "@coconut-xr/apfel-kruemel";
import { Container, Text } from "@coconut-xr/koestlich";
import { X } from "@coconut-xr/lucide-koestlich";
import { useEntities } from "miniplex-react";
import { defaultScene } from "../engine/DefaultScene";
import { ECS, Entity } from "../engine/ecs/state";
import { serializeEntity } from "../engine/serializeEntity";
import { useClearScene } from "../engine/useClearScene";
import { useRemoveSceneItem } from "../engine/useRemoveSceneItem";
import { useRestoreScene } from "../engine/useRestoreScene";
import { useSavedScenes } from "../engine/useSavedScenes";
export const SceneTreeMenu = () => {
	const clearScene = useClearScene();
	const restoreScene = useRestoreScene();
	const removeItem = useRemoveSceneItem();
	const { scenes, saveScene, removeScene, clearScenes } = useSavedScenes();
	const entities = useEntities(ECS.world);
	return (
		<>
			<Container flexDirection="column">
				<Text>Scene Tree</Text>
				<Button onClick={() => clearScene()}>
					<Text>Clear Scene</Text>
				</Button>
				<Button onClick={() => restoreScene(defaultScene)}>
					<Text>Restore Default Scene</Text>
				</Button>
				{entities.entities.map((item: Entity) => (
					<Container key={item.id} flexDirection="row">
						<Text color={"white"}>{item.type}</Text>
						<IconButton onClick={() => removeItem(item.id)}>
							<X />
						</IconButton>
					</Container>
				))}
				<Container flexDirection="column">
					<Text>Saved Scenes</Text>
					{Object.entries(scenes).map(([sceneId, scene]) => (
						<Container key={sceneId} flexDirection="row">
							<Text color={"white"}>{sceneId}</Text>
							<IconButton onClick={() => removeScene(sceneId)}>
								<X />
							</IconButton>
						</Container>
					))}
					<Button onClick={() => saveScene("test", {items: entities.entities.map((item) => serializeEntity(item))})}>
						<Text>Save Scene</Text>
					</Button>
					<Button onClick={() => clearScenes()}>
						<Text>Clear Saved Scenes</Text>
					</Button>
				</Container>
			</Container>
		</>
	);
};
