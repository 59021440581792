import { RPC } from "playroomkit";
import { useEffect } from "react";
import type { Matrix4 } from "three";
import { ECS, type SyncedEntity } from "./ecs/state";

export interface AddItemProps
	extends Omit<SyncedEntity, "transform" | "id" | "type"> {
	parentId?: string;
	isNetworked?: boolean;
	overrideId?: string;
	scale?: [number, number, number];
	forceUpright?: boolean;
	position?: [number, number, number];
	transform?: Matrix4;
	quaternion?: [number, number, number, number];
}

export const useRemoveSceneItem = () => {
	return async (id: string): Promise<void> => {
		await RPC.call("removeSceneItem", id, RPC.Mode.ALL);
	};
};

export const useRegisterRemoveSceneItem = () => {
	useEffect(() => {
		return RPC.register("removeSceneItem", async (id: string) => {
			const entity = ECS.world.where((e) => e.id === id).first;
			if (!entity) {
				console.warn("Entity not found", id);
				return;
			}
			ECS.world.remove(entity);
		});
	}, []);
};
