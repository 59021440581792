import {
	useConnectionState,
	useEnsureRoom,
	useParticipants,
	useTrackToggle,
} from "@livekit/components-react";
import { ConnectionState, Track } from "livekit-client";
import { useCallback, useEffect, useState } from "react";
import type { VideoTileProps } from "../components/livekit/ScreenshareTile";
import { useAddSceneItem } from "../engine/useAddSceneItem";
import { DeepgramMicrophone } from "../menu/DeepgramMicrophone";
import { PankacakeSpawnerMenu } from "./PSpawnerMenu";
import { PankacakeSceneTree } from "./SceneTree";

export const PancakeMenu = () => {
	const room = useEnsureRoom();
	const others = useParticipants();
	const [name, setName] = useState<string>("");
	const connectionState = useConnectionState();
	useEffect(() => {
		if (connectionState === ConnectionState.Connected) {
			room.localParticipant.setName(name ?? "");
		}
	}, [name, room.localParticipant, connectionState]);

	const {
		enabled: micEnabled,
		pending: micPending,
		toggle: micToggle,
	} = useTrackToggle({
		source: Track.Source.Microphone,
	});
	const {
		enabled: camEnabled,
		pending: camPending,
		toggle: camToggle,
	} = useTrackToggle({
		source: Track.Source.Camera,
	});
	const {
		enabled: screenShareEnabled,
		pending: screenSharePending,
		toggle: screenShareToggle,
	} = useTrackToggle({
		source: Track.Source.ScreenShare,
	});

	const connect = useCallback(() => {
		console.log("connect");
		const wsURL = "wss://playground-d8j6xe0w.livekit.cloud";

		if (connectionState === ConnectionState.Connected) {
			room.disconnect();
		} else {
			fetch("https://xrsandbox0r4a8b1i-livekit.functions.fnc.fr-par.scw.cloud/")
				.then((response) => response.json())
				.then((data) => {
					const token = data.jwt;
					if (token) {
						room.connect(wsURL, token);
					}
				});
		}
	}, [connectionState, room]);
	const addSceneItem = useAddSceneItem();

	return (
		<div
			style={{
				position: "absolute",
				left: 0,
				top: 0,
				width: "20%",
				height: "100%",
				backgroundColor: "lightgray",
				padding: "20px",
				borderRadius: "10px",
				boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
			}}
		>
			<h2>Livekit Controls</h2>

			{connectionState === ConnectionState.Connected ? (
				<>
					<div>Connected as {name}</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "20px",
						}}
					>
						<input
							type="checkbox"
							checked={micEnabled}
							onChange={() => micToggle()}
						/>
						<label style={{ marginLeft: "10px" }}>Toggle Microphone</label>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "20px",
						}}
					>
						<input
							type="checkbox"
							checked={camEnabled}
							onChange={() => camToggle()}
						/>
						<label style={{ marginLeft: "10px" }}>Toggle Camera</label>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "20px",
						}}
					>
						<input
							type="checkbox"
							checked={screenShareEnabled}
							onChange={() => screenShareToggle()}
						/>
						<label style={{ marginLeft: "10px" }}>Toggle Screen Share</label>
					</div>
					<hr style={{ margin: "20px 0" }} />
					<h3>Connected Clients</h3>
					{others.map((participant) => (
						<div
							key={participant.sid}
							style={{
								fontSize: "1.2em",
								fontWeight: "bold",
								color: "blue",
							}}
						>
							{participant.name} <sub>{participant.sid}</sub>
							{[...participant.videoTrackPublications].map(([, track]) => (
								<div key={track.source}>
									<button
										onClick={() => {
											addSceneItem<VideoTileProps>("videoTile", {
												participantName: participant.name ?? "",
												participantIdentity: participant.identity,
												sourceType: track.source,
											});
										}}
										style={{
											padding: "10px",
											margin: "10px 0",
											width: "100%",
											borderRadius: "5px",
											border: "1px solid lightgray",
										}}
										type="button"
									>
										{track.source}
									</button>
								</div>
							))}
						</div>
					))}
				</>
			) : (
				<div>
					Not connected
					<input
						type="text"
						value={name}
						onChange={(e) => setName(e.target.value)}
						style={{
							padding: "10px",
							margin: "10px 0",
							width: "100%",
							borderRadius: "5px",
							border: "1px solid lightgray",
						}}
					/>
					<button
						onClick={connect}
						disabled={
							!name ||
							name.length === 0 ||
							connectionState !== ConnectionState.Disconnected
						}
						style={{
							padding: "10px 20px",
							borderRadius: "5px",
							background: "lightblue",
							color:
								connectionState === ConnectionState.Disconnected
									? "red"
									: "white",
							border: "none",
						}}
						type="button"
					>
						Connect
					</button>
				</div>
			)}
			<hr style={{ margin: "20px 0" }} />
			<DeepgramMicrophone />
			<PankacakeSceneTree />
			<PankacakeSpawnerMenu />
		</div>
	);
};
