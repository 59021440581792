import { QuadLayer } from "@coconut-xr/natuerlich/react";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { useItemState } from "../../engine/useItemState";

export interface ImageTileProps {
	url: string;
}

export const ImageTile = () => {
	const [{ url }] = useItemState<ImageTileProps>();
	const imageTexture = useLoader(TextureLoader, url);
	const aspectRatio = imageTexture.image.width / imageTexture.image.height;
	return (
		<>
			<QuadLayer
				scale={[1, 1 / aspectRatio, 1]}
				texture={imageTexture}
				pixelWidth={imageTexture.image.width}
				pixelHeight={imageTexture.image.height}
			/>
		</>
	);
};
