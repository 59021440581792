import { Container, Text } from "@coconut-xr/koestlich";
import { Vector3 } from "three";

export const Vector3Display = ({ vector }: { vector: Vector3 }) => {
	const colors = ["#4CAF50", "#2196F3", "#FFC107"]; // Pleasant CAD-like colors: Green, Blue, Amber
	return (
		<Container flexDirection="row">
			{vector.toArray().map((value, index) => (
				<Text
					key={`vector-value-${value}-${index}`}
					padding={2}
					margin={2}
					borderRadius={3}
					backgroundColor={colors[index % colors.length]}
					color={"#FFFFFF"} // White text color for better readability
				>
					{value.toFixed(2)}
				</Text>
			))}
		</Container>
	);
};
