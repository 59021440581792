import { useLoader } from "@react-three/fiber";
import type { AvatarProps } from "@readyplayerme/visage";
import { useMemo } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export type PlayerAvatarProps = AvatarProps;
const DEFAULT_HAND_PROFILE = "generic-hand";
export const DEFAULT_PROFILES_PATH =
	"https://cdn.jsdelivr.net/npm/@webxr-input-profiles/assets@1.0/dist/profiles";

export function getMotionHandModelUrl(
	handedness: string,
	basePath = DEFAULT_PROFILES_PATH,
	defaultProfileId = DEFAULT_HAND_PROFILE,
) {
	return `${basePath}/${defaultProfileId}/${handedness}.glb`;
}

export const PlayerAvatar = ({ modelSrc }: { modelSrc: string }) => {
	const modelLeft = useLoader(GLTFLoader, getMotionHandModelUrl("left"));
	const modelRight = useLoader(GLTFLoader, getMotionHandModelUrl("right"));
	const modelHead = useLoader(GLTFLoader, "/head/scene.gltf");
	const copiedLeft = useMemo(() => modelLeft.scene.clone(), [modelLeft]);
	const copiedRight = useMemo(() => modelRight.scene.clone(), [modelRight]);
	const copiedHead = useMemo(() => {
		const m = modelHead.scene.clone();
		m.children[0].scale.set(0.05, 0.05, 0.05);
		m.children[0].rotateY(Math.PI);
		return m;
	}, [modelHead]);
	return (
		<mesh>
			<primitive name="Left" object={copiedLeft} />
			<primitive name="Right" object={copiedRight} />
			<primitive name="Head" object={copiedHead} />
		</mesh>
	);
};
