export const holodeckShader = {
	vertexShader: `
		varying vec3 vPosition;
		void main() {
			vPosition = position;
			gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
		}
	`,
	fragmentShader: `
		varying vec3 vPosition;
		uniform float gridWidth;
		uniform float gridSpacing;

		void main() {
			bool inRange = abs(mod(vPosition.x, gridSpacing) - gridSpacing * 0.5) < gridWidth || 
						   abs(mod(vPosition.z, gridSpacing) - gridSpacing * 0.5) < gridWidth || 
						   abs(mod(vPosition.y, gridSpacing) - gridSpacing * 0.5) < gridWidth;
			if (inRange) {
				gl_FragColor = vec4(0.902, 0.706, 0.412, 1.0); // Custom color for grid lines
			} else {
				gl_FragColor = vec4(0.1, 0.1, 0.1, 1.0); // Dark gray for background
			}
		}
	`,
	uniforms: {
		gridWidth: { value: 0.015 },
		gridSpacing: { value: 0.5 },
	},
};
