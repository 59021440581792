import type { XRState } from '@coconut-xr/natuerlich/react'

export interface PlaneInfo {
  points: [number, number][]
  transform: number[]
  semanticLabel?: string
}

export interface RoomInfo {
  planes: PlaneInfo[]
}

export async function computeRoomGeometry(
  xrState: Partial<XRState>
): Promise<RoomInfo | null> {
  return new Promise((resolve) => {
    xrState.onNextFrameCallbacks?.add((state, delta, frame) => {
      const referenceSpace = state.gl.xr.getReferenceSpace()
      if (!frame) {
        console.warn('No frame available')
        return null
      }
      if (!referenceSpace) {
        console.warn('No reference space available')
        return null
      }

      resolve({
        planes: (xrState.trackedPlanes ?? [])
          .map((plane) => {
            const pose = frame.getPose(plane.planeSpace, referenceSpace)
            if (!pose) {
              console.warn('No pose available for plane', plane)
              return null
            }
            return {
              points: plane.polygon.map((point) => {
                return [point.x, point.z]
              }),
              transform: Array.from(pose.transform.matrix) as number[],
              semanticLabel: plane.semanticLabel,
            } as PlaneInfo
          })
          .filter((plane): plane is PlaneInfo => plane !== null),
      })
    })
  })
}
