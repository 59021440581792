import { Glass, TabBar, TabBarItem } from "@coconut-xr/apfel-kruemel";
import { Container, Text } from "@coconut-xr/koestlich";
import { Network, Plus, Settings, Video } from "@coconut-xr/lucide-koestlich";
import { KoestlichQuadLayer } from "@coconut-xr/natuerlich/defaults";
import { SketchFabSpawner } from "../SketchFabSpawner";
import { LivekitControls } from "../components/livekit/LivekitControls";
import { useItemState } from "../engine/useItemState";
import { SceneTreeMenu } from "./SceneTreeMenu";
import { SettingsMenu } from "./SettingsMenu";
import { SpawnerMenu } from "./SpawnerMenu";

export interface Tab {
	icon: React.ReactNode;
	label: string;
	content: React.ReactNode;
}

export interface MainMenuProps {
	selectedTab: number;
}

const n = 0;

export const MainMenu = () => {
	const tabs: Tab[] = [
		{
			icon: <Settings />,
			label: "Settings",
			content: <SettingsMenu key="settings-menu" />,
		},
		{
			icon: <Video />,
			label: "Conference",
			content: <LivekitControls key="livekit-controls" />,
		},
		{
			icon: <Plus />,
			label: "Spawn Stuff",
			content: <SpawnerMenu key="spawner-menu" />,
		},
		{
			icon: <Network />,
			label: "Scene Tree",
			content: <SceneTreeMenu key="scene-tree-menu" />,
		},
		{
			icon: <Network />,
			label: "Sketchfab",
			content: <SketchFabSpawner key="sketchfab-spawner" />,
		},
	];
	const [{ selectedTab }, setState] = useItemState<MainMenuProps>();
	return (
		<KoestlichQuadLayer pixelWidth={640} pixelHeight={640} transparent>
			<Container
				height={"100%"}
				flexDirection="row"
				alignItems="center"
				justifyContent="center"
			>
				<TabBar
					onValueChange={(value: number) => {
						setState({ selectedTab: value });
					}}
					value={selectedTab ?? 0}
				>
					{tabs.map((tab, index) => (
						<TabBarItem key={tab.label} value={index} icon={tab.icon}>
							<Text>{tab.label}</Text>
						</TabBarItem>
					))}
				</TabBar>
				<Glass
					padding={10}
					borderRadius={20}
					marginLeft={100}
					flexDirection="column"
				>
					{tabs[selectedTab ?? 0].content}
				</Glass>
			</Container>
		</KoestlichQuadLayer>
	);
};
