import { useItemState } from "../engine/useItemState";

export interface PointLightProps {
	color: string;
	intensity: number;
}

export const PointLight = () => {
	const [props] = useItemState<PointLightProps>();

	return (
		<>
			<mesh>
				<boxGeometry args={[0.1, 0.1, 0.1]} />
				<meshStandardMaterial color={props.color ?? "orange"} />
			</mesh>
			<pointLight color={props.color ?? "orange"} intensity={props.intensity} />
		</>
	);
};
