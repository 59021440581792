import { Container, RootContainer, Text } from "@coconut-xr/koestlich";

import { useEffect, useRef, useState } from "react";
import { Mesh, Object3D, Vector3 } from "three";
import { Vector3Display } from "./Vector3Display";

export const SelfLocator = () => {
	const [chain, setChain] = useState<Array<[string, Vector3]>>([]);

	const selfLocatorRef = useRef<Mesh>(null);
	useEffect(() => {
		if (!selfLocatorRef.current) {
			return;
		}
		const selfLocator = selfLocatorRef.current;
		const chain: Array<[string, Vector3]> = [];
		let current: Object3D | null = selfLocator;
		while (current) {
			chain.push([current.constructor.name, current.position]);
			current = current.parent;
		}

		setChain(chain);
	}, []);

	return (
		<mesh ref={selfLocatorRef}>
			<RootContainer backgroundColor={"lightgray"} padding={5}>
				<Container>
					{chain.map(([name, vector], index) => (
						<Container flexDirection={"row"} key={`${name}-${index}`}>
							<Text>{name}</Text>
							<Vector3Display vector={vector} />
						</Container>
					))}
				</Container>
			</RootContainer>
		</mesh>
	);
};
