import { Plane } from "@react-three/drei";
import { Physics, RigidBody } from "@react-three/rapier";
import { useIsHost } from "playroomkit";
import type React from "react";
import { Suspense } from "react";
import type { Behaviour } from "./Behaviour";
import { EntitiesRenderer } from "./EntitiesRenderer";
import type { SceneItem } from "./SceneItem";
import { AvatarSystem } from "./ecs/AvatarSystem";
import { MultiplayerSystem } from "./ecs/MultiplayerSystem";
import { PositionSystem } from "./ecs/PositionSystem";
import { WorkersSystem } from "./ecs/WorkersSystem";
import { useRegisterAddSceneItem } from "./useAddSceneItem";
import { useRegisterClearScene } from "./useClearScene";
import { useRPCGrabItem } from "./useGrablock";
import { useRPCResync } from "./useItemTransform";
import { useRegisterRemoveSceneItem } from "./useRemoveSceneItem";

export type SceneItems = Record<
	string,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	React.FunctionComponent<SceneItem & any>
>;

export interface NetworkSyncedSceneProps {
	items: SceneItems;
	behaviours: Behaviour[];
}

export const NetworkSyncedScene = () => {
	useRPCResync();
	useRPCGrabItem();
	useRegisterAddSceneItem();
	useRegisterRemoveSceneItem();
	useRegisterClearScene();
	const isHost = useIsHost();
	return (
		<Suspense fallback={null}>
			<Physics paused={!isHost} debug={false}>
				<PositionSystem />
				<MultiplayerSystem />
				<WorkersSystem />
				<AvatarSystem />
				<EntitiesRenderer />
				<RigidBody type="fixed">
					<Plane args={[1, 1]} rotation={[-0.5 * Math.PI, 0, 0]} />
				</RigidBody>
			</Physics>
		</Suspense>
	);
};
