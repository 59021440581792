import { useEntities } from "miniplex-react";
import { defaultScene } from "../engine/DefaultScene";
import { ECS, type Entity } from "../engine/ecs/state";
import { serializeEntity } from "../engine/serializeEntity";
import { useClearScene } from "../engine/useClearScene";
import { useRemoveSceneItem } from "../engine/useRemoveSceneItem";
import { useRestoreScene } from "../engine/useRestoreScene";
import { useSavedScenes } from "../engine/useSavedScenes";

export const PankacakeSceneTree = () => {
	const clearScene = useClearScene();
	const restoreScene = useRestoreScene();
	const removeItem = useRemoveSceneItem();
	const { scenes, saveScene, removeScene, clearScenes } = useSavedScenes();
	const entities = useEntities(ECS.world);
	return (
		<div>
			<h3>Scene</h3>{" "}
			<button
				onClick={clearScene}
				type="button"
				style={{
					padding: "10px 20px",
					borderRadius: "5px",
					background: "lightblue",
					color: "white",
					border: "none",
				}}
			>
				Clear
			</button>
			<button
				onClick={() => restoreScene(defaultScene)}
				type="button"
				style={{
					padding: "10px 20px",
					borderRadius: "5px",
					background: "lightblue",
					color: "white",
					border: "none",
					marginLeft: "10px",
				}}
			>
				Default
			</button>
			{entities.entities.map((item: Entity) => (
				<div
					key={item.id}
					style={{
						margin: "10px 0",
						width: "100%",
						borderRadius: "5px",
						border: "1px solid lightgray",
					}}
				>
					{item.type}
					<button
						onClick={() => {
							removeItem(item.id);
						}}
						type="button"
						style={{
							padding: "10px 20px",
							borderRadius: "5px",
							background: "lightblue",
							color: "white",
							border: "none",
							marginLeft: "10px",
						}}
					>
						Remove
					</button>
				</div>
			))}
			<h3>Saved Scenes</h3>
			{Object.entries(scenes).map(([sceneId, scene]) => (
				<div key={sceneId}>
					{sceneId}{" "}
					<button onClick={() => restoreScene(scenes[sceneId])} type="button">
						Restore
					</button>
					<button onClick={() => removeScene(sceneId)} type="button">
						Remove
					</button>
				</div>
			))}
			<button
				onClick={clearScenes}
				type="button"
				style={{
					padding: "10px 20px",
					borderRadius: "5px",
					background: "lightblue",
					color: "white",
					border: "none",
				}}
			>
				Clear All
			</button>
			<button
				onClick={() => saveScene("test", {items: entities.entities.map((item) => serializeEntity(item))})}
				type="button"
				style={{
					padding: "10px 20px",
					borderRadius: "5px",
					background: "lightblue",
					color: "white",
					border: "none",
					marginLeft: "10px",
				}}
			>
				Save
			</button>
		</div>
	);
};
