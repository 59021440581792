import { Matrix4, Quaternion, Vector3 } from "three";
import type { RealtimeSyncedEntity, SyncedEntity } from "./ecs/state";
import type { ItemTransform } from "./useItemTransform";

export type SerializableEntity = Omit<
	RealtimeSyncedEntity & SyncedEntity,
	"transform"
> & {
	transform: ItemTransform;
};

export type SerializedEntity = Omit<
	RealtimeSyncedEntity & SyncedEntity,
	"transform"
> & {
	transform: number[];
};

export function deserializeEntity(
	entity: SerializedEntity,
): SerializableEntity {
	const { transform, ...item } = entity;
	const transformMatrix = new Matrix4().fromArray(transform);
	const position = new Vector3();
	const quaternion = new Quaternion();
	const scale = new Vector3();
	transformMatrix.decompose(position, quaternion, scale);

	return {
		...item,
		transform: {
			matrix: transformMatrix,
			position,

			quaternion,
			scale,
		},
	};
}

export function serializeEntity(entity: SerializableEntity): SerializedEntity {
	const { transform, ...item } = entity;
	return {
		...item,
		transform: transform.matrix.toArray(),
	};
}
