import {
	Loader3DTiles,
	PointCloudColoring,
	type Runtime,
} from "three-loader-3dtiles";

import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import {
	PerspectiveCamera,
	Plane,
	Vector2,
	Vector3,
	type Group,
	type Mesh,
	type Object3D,
} from "three";
import { useItemState } from "../../engine/useItemState";

export interface PointCloudProps {
	url: string;
}

const clippingPlanes = [
	new Plane(new Vector3(1, 0, 0), 2),
	new Plane(new Vector3(-1, 0, 0), 2),
	new Plane(new Vector3(0, 1, 0), 2),
	new Plane(new Vector3(0, -1, 0), 2),
	new Plane(new Vector3(0, 0, 1), 2),
	new Plane(new Vector3(0, 0, -1), 2),
];

export const PointCloud = () => {
	const { gl, camera: pancakeCamera } = useThree();
	const [{ url }] = useItemState<PointCloudProps>();

	const tilesRuntime = useRef<Runtime | null>(null);
	const modelRef = useRef<Object3D | null>(null);
	const cameraBoxRef = useRef<Mesh | null>(null);
	const parentRef = useRef<Group | null>(null);

	useEffect(() => {
		async function loadTileset() {
			gl.localClippingEnabled = true;
			const result = await Loader3DTiles.load({
				url,
				renderer: gl,
				options: {
					viewDistanceScale: 1,
					// material: new PointsMaterial({
					// 	vertexColors: true,
					// 	size: 0.01,
					// 	clippingPlanes,
					// }),
					pointSize: 1,
					cesiumIONToken:
						"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3NjEwMjA4Ni00YmVkLTQyMjgtYjRmZS1lY2M3ZWFiMmFmNTYiLCJpZCI6MjYxMzMsImlhdCI6MTY3NTM2ODY4NX0.chGkGL6DkDNv5wYJQDMzWIvi9iDoVa27dgng_5ARDmo",
					resetTransform: true,
					updateTransforms: true,
					pointCloudColoring: PointCloudColoring.RGB,
					maximumScreenSpaceError: 15000,
					maximumMemoryUsage: 100,

					memoryAdjustedScreenSpaceError: true,
					dracoDecoderPath:
						"https://cdn.jsdelivr.net/npm/three@0.160.0/examples/jsm/libs/draco",
					basisTranscoderPath:
						"https://cdn.jsdelivr.net/npm/three@0.160.0/examples/jsm/libs/basis",
				},
			});
			const { model, runtime } = result;
			model.scale.set(0.0001, 0.0001, 0.0001);
			tilesRuntime.current = runtime;

			parentRef.current?.add(model);
			modelRef.current = model;
		}
		loadTileset().then().catch(console.error);
		return () => {
			tilesRuntime.current?.dispose();
			if (modelRef.current) {
				parentRef.current?.remove(modelRef.current);
			}
		};
	}, [url, gl]);

	useFrame(({ size, gl }, delta, frame) => {
		if (tilesRuntime.current) {
			if (frame) {
				const cam = gl.xr.getCamera();
				const ref = gl.xr.getReferenceSpace();
				const pose = frame.getViewerPose(ref);
				if (pose) {
					const fovi = pose.views[0].projectionMatrix[5];
					cam.fov = (Math.atan2(1, fovi) * 2 * 180) / Math.PI;
				}
				if (cameraBoxRef.current && parentRef.current) {
					const cam2 = new PerspectiveCamera(
						cam.fov,
						size.width / size.height,
						0.1,
						1000,
					);
					const AO = parentRef.current.matrixWorld.clone().invert();
					const OB = cam.matrixWorld.clone();
					const AB = AO.multiply(OB);
					cameraBoxRef.current.matrix = AB;
					cameraBoxRef.current.matrix.decompose(
						cam2.position,
						cam2.quaternion,
						cam2.scale,
					);
					// cameraBoxRef.current.position.multiplyScalar(1);
				}
				tilesRuntime.current.update(
					delta,
					new Vector2(size.width, size.height),
					cam,
				);
			} else {
				if (cameraBoxRef.current && parentRef.current) {
					const AO = parentRef.current.matrixWorld.clone().invert();
					const OB = pancakeCamera.matrixWorld.clone();
					const AB = AO.multiply(OB);
					cameraBoxRef.current.matrix = AB;
					cameraBoxRef.current.matrix.decompose(
						cameraBoxRef.current.position,
						cameraBoxRef.current.quaternion,
						cameraBoxRef.current.scale,
					);
					// cameraBoxRef.current.position.multiplyScalar(1);
				}
				tilesRuntime.current.update(
					delta,
					new Vector2(size.width, size.height),
					pancakeCamera,
				);
			}
		}
	});

	return (
		<group
			ref={parentRef}
			dispose={() => tilesRuntime.current?.dispose()}
			rotation={[-Math.PI / 2, 0, 0]}
			// scale={[0.0001, 0.0001, 0.0001]}
		>
			{/* <mesh ref={cameraBoxRef} visible={false}>
				<boxGeometry args={[0.01, 0.01, 0.3]} />
				<meshBasicMaterial color="red" side={DoubleSide} />
			</mesh> */}
		</group>
	);
};
