import { Grabbable } from "@coconut-xr/natuerlich/defaults";
import { RigidBody, type RapierRigidBody } from "@react-three/rapier";
import { RPC } from "playroomkit";
import React, { useRef } from "react";
import { Euler } from "three";
import { HandleType } from "./HandleType";
import { availableItems } from "./availableItems";
import { ECS } from "./ecs/state";
import { ItemIdProvider } from "./useItemState";

export const NetworkedItem = React.memo(() => {
	const entity = ECS.useCurrentEntity();
	// const transform = useItemTransform();
	// console.log("rerendering", entity);

	if (
		!availableItems[entity.type] ||
		typeof availableItems[entity.type] !== "function"
	) {
		console.warn(`No item found for type ${entity.type}`, availableItems);
		return null;
	}
	const ref = useRef<RapierRigidBody>(null);
	return (
		<>
			{entity.handleType !== HandleType.NONE ? (
				<ECS.Component name="handle">
					<Grabbable
						// getTransform={() => transform}
						onGrabbed={async () => {
							await RPC.call("grabItem", entity.id, RPC.Mode.ALL);
							// ECS.world.addComponent(entity, "grabbedBy", me().id);
							// ECS.world.addComponent(entity, "controlled", true);
						}}
						onReleased={async () => {
							// ECS.world.removeComponent(entity, "grabbedBy");
							// console.log("request releasing item");
							await RPC.call("releaseItem", entity.id, RPC.Mode.ALL);
							// ECS.world.removeComponent(entity, "controlled");
						}}
					>
						<mesh name="handle" position={[0, 0, 0]}>
							<boxGeometry args={[0.1, 0.1, 0.1]} />
							<meshStandardMaterial color={"green"} opacity={0.5} transparent />
						</mesh>
					</Grabbable>
				</ECS.Component>
			) : null}

			<ItemIdProvider id={entity.id}>
				{entity.physical ? (
					<RigidBody
						ref={ref}
						type={entity.physical}
						colliders={entity.colliders}
						position={entity.transform.position.toArray()}
						rotation={new Euler().setFromQuaternion(
							entity.transform.quaternion,
						)}
						linearVelocity={
							entity.velocities
								? [
										entity.velocities.linear.x,
										entity.velocities.linear.y,
										entity.velocities.linear.z,
									]
								: [0, 0, 0]
						}
						angularVelocity={
							entity.velocities
								? [
										entity.velocities.angular.x,
										entity.velocities.angular.y,
										entity.velocities.angular.z,
									]
								: [0, 0, 0]
						}
						gravityScale={0.1}
					>
						<ECS.Component name="rapier" data={ref} />
						<ECS.Component name="child">
							<mesh>
								{React.createElement(availableItems[entity.type], {
									key: entity.id + "-physical",
									...entity,
								})}
							</mesh>
						</ECS.Component>
					</RigidBody>
				) : (
					<>
						<ECS.Component name="child">
							<mesh>
								{React.createElement(availableItems[entity.type], {
									key: entity.id,
									...entity,
								})}
							</mesh>
						</ECS.Component>
					</>
				)}
			</ItemIdProvider>
		</>
	);
});
