import { RPC } from "playroomkit";
import { ECS } from "./ecs/state";

export const useClearScene = () => {
	return async () => {
		await RPC.call("clearScene", null, RPC.Mode.ALL);
	};
};

export const useRegisterClearScene = () => {
	return RPC.register("clearScene", async () => {
		ECS.world.clear();
	});
};
