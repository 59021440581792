import { useThree } from "@react-three/fiber";
import { myPlayer, usePlayersList } from "playroomkit";
import { useEffect } from "react";
import { NetworkSyncedScene } from "./NetworkSyncedScene";

export const MainScene = () => {
	const s = useThree((state) => state.scene);
	const playerList = usePlayersList();
	useEffect(() => {
		globalThis.scene = s;
	}, [s]);
	const me = myPlayer();
	return (
		<>
			{/* <CrossHair /> */}
			{/* <mesh position={[0, 1, 0]}>
				<Stats />
			</mesh> */}
			{/* ADD ITEMS TO THE SCENE VIA DefaultScene.ts */}
			<ambientLight intensity={0.5} />
			{/* ADD ITEMS TO THE SCENE VIA DefaultScene.ts */}
			<NetworkSyncedScene />
		</>
	);
};
