import { getState, setState } from "playroomkit";
import { useCallback, useEffect, useRef } from "react";
import screenfull from "screenfull";
export type TouchState = {
	x: number;
	y: number;
}[];

export const MobileView = () => {
	// const [touches, setTouches] = useState<TouchState>([]);
	useEffect(() => {
		const touchStartListener = (e: TouchEvent) => {
			e.preventDefault(); // Prevent default touch behavior like scrolling and zooming
		};
		window.addEventListener("touchstart", touchStartListener, {
			passive: false,
		});
		return () => {
			window.removeEventListener("touchstart", touchStartListener);
		};
	}, []);

	const update = useCallback(() => {
		const touches = getState("touches") ?? [];
		requestAnimationFrame(update);
		if (!ref.current) {
			return;
		}
		if (!Array.isArray(touches)) {
			ref.current.style.left = "10%";
			ref.current.style.top = "10%";
			return;
		}
		if (touches.length === 0) {
			ref.current.style.left = "50%";
			ref.current.style.top = "50%";
			return;
		}
		ref.current.style.left = `${touches[0].x * 100}%`;
		ref.current.style.top = `${touches[0].y * 100}%`;
	}, []);

	useEffect(() => {
		requestAnimationFrame(update);
	}, [update]);

	const ref = useRef<HTMLDivElement>(null);
	const handleTouch = useCallback((e: React.TouchEvent) => {
		if (screenfull.isEnabled && !screenfull.isFullscreen) {
			screenfull.request();
		}

		const bounds = e.currentTarget.getBoundingClientRect();
		const touches = Array.from(e.touches).map((t) => ({
			x: (t.clientX - bounds.left) / bounds.width,
			y: (t.clientY - bounds.top) / bounds.height,
		}));
		// setTouches(touches);
		setState("touches", touches, true);
	}, []);

	return (
		<div
			onTouchStart={handleTouch}
			onTouchMove={handleTouch}
			onTouchEnd={handleTouch}
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "black",
				color: "white",
				fontSize: "2em",
			}}
		>
			<div
				ref={ref}
				// key={i}
				style={{
					backgroundColor: "red",
					width: "150px",
					height: "150px",
					borderRadius: "50%",
					position: "absolute",
					transform: "translate(-50%, -50%)",
					pointerEvents: "none", // Disable touch interactions
				}}
			>
				{/* {touch.x.toFixed(1)}, {touch.y.toFixed(1)} */}
			</div>
		</div>
	);
};
