import { useXR } from "@coconut-xr/natuerlich/react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import {
	Matrix4,
	PlaneGeometry,
	Quaternion,
	TextureLoader,
	Vector3,
	type Mesh,
	type Texture,
} from "three";

const positionHelper = new Vector3();
const quaternionHelper = new Quaternion();
const scaleHelper = new Vector3();
const matrixHelper = new Matrix4();

const planeHelper = new PlaneGeometry();
export const VideoTile = ({ video }: { video: HTMLVideoElement }) => {
	const internalRef = useRef<Mesh>(null);

	const { gl } = useThree();
	const fallbackTexture = useLoader(TextureLoader, "samplecode.png");
	const [aspectRatio, setAspectRatio] = useState(1);
	const [activeTexture, setActiveTexture] = useState<Texture>(fallbackTexture);

	const layer = useMemo(() => {
		const session = gl.xr.getSession();
		if (!session) {
			return;
		}
		if (!video.videoWidth) {
			return;
		}
		const binding = new XRMediaBinding(session);
		const space = gl.xr.getReferenceSpace();
		if (!space) {
			return;
		}
		const layer = binding.createQuadLayer(video, {
			space,
			width: Math.max(video.videoWidth, 100),
			height: Math.max(video.videoHeight, 100),
			transform: new XRRigidTransform(),
		});
		setAspectRatio(video.videoHeight / video.videoWidth);
		return layer;
	}, [gl.xr, video]);

	useEffect(() => {
		video.addEventListener("playing", () => {
			setAspectRatio(video.videoHeight / video.videoWidth);
		});
	}, [video]);

	const index = -1;
	useEffect(() => {
		if (layer == null) {
			return;
		}
		const state = useXR.getState();
		state.addLayer(index, layer);
		return () => {
			layer.destroy();
			state.removeLayer(layer);
		};
	}, [layer]);

	function updateLayerScale(layer: XRQuadLayer, scale: Vector3) {
		layer.width = scale.x / 2;
		layer.height = scale.y / 2;
	}

	useFrame((state) => {
		if (layer != null && internalRef.current != null) {
			matrixHelper
				.multiplyMatrices(state.camera.matrix, state.camera.matrixWorldInverse)
				.multiply(internalRef.current.matrixWorld)
				.decompose(positionHelper, quaternionHelper, scaleHelper);
			layer.transform = new XRRigidTransform(positionHelper, quaternionHelper);
			updateLayerScale(layer, scaleHelper);
		}
	});

	return (
		<mesh
			renderOrder={layer != null ? -1000 : undefined}
			ref={internalRef}
			scale={[1, aspectRatio, 1]}
			// visible={layer == null}
			geometry={planeHelper}
		>
			<meshBasicMaterial
				map={activeTexture}
				depthWrite={true}
				colorWrite={false}
				toneMapped={false}
			/>
		</mesh>
	);
};
