import { useFrame } from "@react-three/fiber";
import type { RapierRigidBody } from "@react-three/rapier";
import { isHost, me } from "playroomkit";
import { Box3, Matrix4, Vector3, type Mesh } from "three";
import { useAnchorTransform } from "../AnchorTransform";
import type { ItemTransform } from "../useItemTransform";
import { ECS, FORCE_UPDATE, JUST_SPAWNED, type Entity } from "./state";

/**
 * dirty items have been moved by physics or some other system
 */
const allEntities = ECS.world;

const boxHelper = new Box3();
const sizeHelper = new Vector3();
const dumpingVector = new Vector3();

const worldToHandle = new Matrix4();
const ancestorToWorld = new Matrix4();

export const PositionSystem = () => {
	/**
	 * strategy:
	 * 1. grabbed items are above all else:
	 *   - copy handle position onto rapier
	 * 2. rapier items that do not sleep are next:
	 *  - copy rapier position onto handle
	 */
	useFrame((_, dt) => {
		const inverseTransform = useAnchorTransform.getState().inverseTransform;
		const myId = me().id;
		for (const entity of allEntities) {
			const { handle, transform, rapier, child, grabbedBy, avatarState } =
				entity;
			if (!child) {
				continue;
			}
			if (avatarState) {
				// console.log("AvatarState", avatarState);
				continue;
			}
			if (entity.physical && !rapier) {
				console.warn("no rapier found for entity", entity);
				continue;
			}

			const rigidBody = rapier?.current;
			// if (grabbedBy === "initial_state") {
			// 	applySharedStateToObject(rigidBody, transform, child, entity.velocities);
			// 	entity.grabbedBy = undefined;
			// 	continue;
			// }
			if (entity.lastSync === JUST_SPAWNED) {
				if (handle) {
					applySharedStateToHandle(handle, transform);
				}
				applySharedStateToObject(
					entity,
					rigidBody,
					transform,
					child,
					entity.velocities,
				);
				entity.lastSync = FORCE_UPDATE;
				continue;
			}

			// NOT GRABBED
			if (grabbedBy === undefined) {
				if (handle) {
					handle.visible = true;
				}
				if (isHost()) {
					// host streams state to clients
					if (!rigidBody || rigidBody?.isSleeping()) {
						continue;
					}
					setSharedStateFromObject(entity, transform, rigidBody);
				} else {
					applySharedStateToObject(
						entity,
						rigidBody,
						transform,
						child,
						entity.velocities,
					);
				}
				// everyone keeps handle in sync with rapier
				if (handle) {
					applySharedStateToHandle(handle, transform);
				}
			} else {
				// someone else is controlling this item
				if (grabbedBy !== myId) {
					// update rapier and handle from shared-state
					if (handle) {
						handle.visible = false;
						applySharedStateToHandle(handle, transform);
					}
					applySharedStateToObject(
						entity,
						rigidBody,
						transform,
						child,
						entity.velocities,
					);
				} else if (handle) {
					// update rapier from handle and mark as dirty
					setSharedStateFromHandle(transform, handle, entity);
					applySharedStateToObject(
						entity,
						rigidBody,
						transform,
						child,
						entity.velocities,
					);
				}
			}
			// get shared-state from rapier and mark as dirty
		}

		function setSharedStateFromObject(
			entity: Entity,
			transform: ItemTransform,
			rigidBody: RapierRigidBody | undefined | null,
		) {
			entity.lastSync = FORCE_UPDATE;
			if (entity.physical && rigidBody) {
				transform.position.copy(rigidBody.translation());
				transform.quaternion.copy(rigidBody.rotation());
				if (!entity.velocities) {
					entity.velocities = {
						linear: new Vector3(),
						angular: new Vector3(),
					};
				}
				entity.velocities.linear = rigidBody.linvel();
				entity.velocities.angular = rigidBody.angvel();
				transform.matrix.compose(
					transform.position,
					transform.quaternion,
					transform.scale,
				);
			} else if (entity.child && !entity.handle) {
				transform.position.copy(entity.child.position);
				transform.quaternion.copy(entity.child.quaternion);
				transform.scale.copy(entity.child.scale);
				transform.matrix.copy(entity.child.matrix);
			}
		}

		function setSharedStateFromHandle(
			transform: ItemTransform,
			handle: Mesh,
			entity: Entity,
		) {
			worldToHandle.copy(handle.matrixWorld);
			worldToHandle.premultiply(inverseTransform);
			transform.matrix.copy(worldToHandle);
			transform.matrix.decompose(
				transform.position,
				transform.quaternion,
				transform.scale,
			);

			entity.lastSync = FORCE_UPDATE;
		}

		function applySharedStateToHandle(handle: Mesh, transform: ItemTransform) {
			handle.position.copy(transform.position);
			handle.quaternion.copy(transform.quaternion);
			handle.scale.copy(transform.scale);
			handle.matrix.copy(transform.matrix);
		}

		function applySharedStateToObject(
			entity: Entity,
			rigidBody: RapierRigidBody | undefined | null,
			transform: ItemTransform,
			child: Mesh,
			velocities = {
				linear: { x: 0, y: 0, z: 0 },
				angular: { x: 0, y: 0, z: 0 },
			},
		) {
			if (!entity.physical) {
				child.position.copy(transform.position);
				child.quaternion.copy(transform.quaternion);
				child.scale.copy(transform.scale);
				child.matrix.copy(transform.matrix);
				return;
			}
			if (!rigidBody) {
				return;
			}
			if (isHost()) {
				rigidBody.setTranslation(transform.position, true);
				rigidBody.setRotation(transform.quaternion, true);
				rigidBody.setLinvel(velocities.linear, true);
				rigidBody.setAngvel(velocities.angular, true);
				const p = child.parent;
				if (p) {
					p.matrix.identity();
					p.matrix.decompose(p.position, p.quaternion, p.scale);
				}
			} else {
				const p = child.parent;
				if (p) {
					p.position.copy(transform.position);
					p.quaternion.copy(transform.quaternion);
					p.scale.copy(transform.scale);
					p.matrix.copy(transform.matrix);
				}
			}
		}
		// for (const entity of controlledByECS) {
		// 	if (!entity.three.matrix) {
		// 		continue;
		// 	}
		// 	if (entity.transform.matrix.equals(entity.three.matrix)) {
		// 		continue;
		// 	}
		// 	entity.three.position.copy(entity.transform.position);
		// 	entity.three.quaternion.copy(entity.transform.quaternion);
		// 	entity.three.scale.copy(entity.transform.scale);
		// 	entity.three.matrix.copy(entity.transform.matrix);
		// }
		// for (const entity of controlledOutsideECS) {
		// 	if (entity.transform.matrix.equals(entity.three.matrix)) {
		// 		continue;
		// 	}
		// 	entity.transform.position.copy(entity.three.position);
		// 	entity.transform.quaternion.copy(entity.three.quaternion);
		// 	entity.transform.scale.copy(entity.three.scale);
		// 	entity.transform.matrix.copy(entity.three.matrix);
		// 	ECS.world.addComponent(entity, "dirty", true);
		// }
	});

	return null;
};
