import { Matrix4, Quaternion, Vector3 } from "three";
import type { CompressedEntity } from "./compressEntity";
import type { Entity } from "./ecs/state";

export function decompressEntity(entity: CompressedEntity): Entity {
	const { transform, ...item } = entity;
	const transformMatrix = new Matrix4().fromArray(new Float32Array(transform));
	const position = new Vector3();
	const quaternion = new Quaternion();
	const scale = new Vector3();
	transformMatrix.decompose(position, quaternion, scale);
	return {
		type: item.type,
		id: item.id,
		handleType: item.handleType,
		physical: item.physical,
		velocities: item.velocities,
		colliders: item.colliders,
		avatarState: item.avatarState,
		// grabbedBy: item.grabbedBy,
		transform: { matrix: transformMatrix, position, quaternion, scale },
	};
}
