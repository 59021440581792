import type {
	RapierRigidBody,
	RigidBodyAutoCollider,
	RigidBodyTypeString,
} from "@react-three/rapier";
import { World } from "miniplex";
import createReactAPI from "miniplex-react";
import { Euler, Matrix4, Quaternion, Vector3, type Mesh } from "three";
import type { HandleType } from "../HandleType";
import type { ItemTransform } from "../useItemTransform";
import type { AvatarState } from "./AvatarSystem";

export interface RealtimeSyncedEntity {
	transform: ItemTransform;
	velocities?: {
		linear: { x: number; y: number; z: number };
		angular: { x: number; y: number; z: number };
	};
	avatarState?: AvatarState;
}

export interface SyncedEntity extends RealtimeSyncedEntity {
	id: string;
	type: string;
	handle?: Mesh;
	loaded?: number;
	target?: string;
	physical?: RigidBodyTypeString;
	colliders?: RigidBodyAutoCollider | false;
	/** controlled items are items that the user is directly manipulating or are moved by rapier */
	remoteControlled?: boolean;
	/** grabbed items are items that are being manipulated by a grab handle */
	grabbedBy?: string;
	handleType?: HandleType;
}

export interface OfflineEntity {
	child?: Mesh;
	rapier?: { current: RapierRigidBody | null };
	/**
	 * time since last forced sync
	 */
	lastSync?: number | typeof FORCE_UPDATE | typeof JUST_SPAWNED;
}

export const FORCE_UPDATE = Number.MAX_SAFE_INTEGER;
export const JUST_SPAWNED = Number.MAX_SAFE_INTEGER - 1; // give systems a chance to do something before next sync

/* Our entity type */
export type Entity = RealtimeSyncedEntity & SyncedEntity & OfflineEntity;

/* Create a Miniplex world that holds our entities */
const world = new World<Entity>();

/* Create and export React bindings */
export const ECS = createReactAPI(world);

export function makeItemTransform({
	position = [0, 0, 0],
	rotation = [0, 0, 0],
	scale = [1, 1, 1],
}): ItemTransform {
	return {
		position: new Vector3(...position),
		quaternion: new Quaternion()
			.identity()
			.setFromEuler(new Euler(...rotation)),
		scale: new Vector3(...scale),
		matrix: new Matrix4().compose(
			new Vector3(...position),
			new Quaternion().identity().setFromEuler(new Euler(...rotation)),
			new Vector3(...scale),
		),
	};
}

globalThis.ECS = ECS;
