import { IconButton } from "@coconut-xr/apfel-kruemel";
import { Volume2, VolumeX } from "@coconut-xr/lucide-koestlich";
import {
	useParticipantTracks,
	useTrackMutedIndicator,
	type TrackReferenceOrPlaceholder,
} from "@livekit/components-react";
import { Track, type Participant } from "livekit-client";

interface MuteParticipantButtonProps {
	participant: Participant;
}

export const MuteParticipantButton = ({
	participant,
}: MuteParticipantButtonProps) => {
	const trackRef: TrackReferenceOrPlaceholder = {
		participant,
		source: Track.Source.Microphone,
	};
	const { isMuted } = useTrackMutedIndicator(trackRef);
	const audioTracks = useParticipantTracks(
		[Track.Source.Microphone],
		participant.identity,
	);

	return (
		<IconButton
			size="xs"
			platter
			onClick={() => {
				for (const { source } of audioTracks) {
					source;
				}
			}}
		>
			{isMuted ? (
				<Volume2 height={14} width={14} />
			) : (
				<VolumeX height={14} width={14} />
			)}
		</IconButton>
	);
};
