import type { PersistableScene } from "./PersistableScene";
import { deserializeEntity } from "./serializeEntity";
import { useAddSceneItem } from "./useAddSceneItem";
import { useClearScene } from "./useClearScene";

export const useRestoreScene = () => {
	const clearScene = useClearScene();
	const addSceneItem = useAddSceneItem();
	return (sceneToRestore: PersistableScene) => {
		clearScene();
		for (const [id, item] of Object.entries(sceneToRestore.items)) {
			addSceneItem(item.type, deserializeEntity(item), { overrideId: id });
		}
	};
};
