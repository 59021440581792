import {
	Loader3DTiles,
	PointCloudColoring,
	type Runtime,
} from "three-loader-3dtiles";

import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Plane, Vector2, Vector3, type Object3D } from "three";
import { useItemState } from "../../engine/useItemState";

export interface Map3dProps {
	lon: number;
	lat: number;
	alt: number;
}

const clippingPlanes = [
	new Plane(new Vector3(1, 0, 0), 2),
	new Plane(new Vector3(-1, 0, 0), 2),
	new Plane(new Vector3(0, 1, 0), 2),
	new Plane(new Vector3(0, -1, 0), 2),
	new Plane(new Vector3(0, 0, 1), 2),
	new Plane(new Vector3(0, 0, -1), 2),
];

export const Map3d = () => {
	const { gl } = useThree();
	const [{ lon, lat, alt }] = useItemState<Map3dProps>();

	const tilesRuntime = useRef<Runtime | null>(null);
	const [model, setModel] = useState<Object3D | null>(null);

	useEffect(() => {
		async function loadTileset() {
			gl.localClippingEnabled = true;
			console.log("loading tileset");
			const result = await Loader3DTiles.load({
				url: "https://tile.googleapis.com/v1/3dtiles/root.json",
				renderer: gl,
				options: {
					viewDistanceScale: 0,
					// material: new PointsMaterial({
					// 	vertexColors: true,
					// 	size: 0.003,
					// }),
					// pointSize: 3,
					googleApiKey: "AIzaSyCyWKvnGKshC_ifqR43L3BDZfQhh-zoPxY",
					resetTransform: true,
					pointCloudColoring: PointCloudColoring.RGB,
					// maximumScreenSpaceError: 20000,
					// maximumMemoryUsage: 1,
					memoryAdjustedScreenSpaceError: true,
					dracoDecoderPath:
						"https://cdn.jsdelivr.net/npm/three@0.160.0/examples/jsm/libs/draco",
					basisTranscoderPath:
						"https://cdn.jsdelivr.net/npm/three@0.160.0/examples/jsm/libs/basis",
				},
			});
			const { model, runtime } = result;
			runtime.orientToGeocoord({ long: lon, lat: lat, height: alt });
			tilesRuntime.current = runtime;
			setModel(model);
			model?.add(runtime.getTileBoxes());
		}
		loadTileset().then().catch(console.error);
	}, [gl, lat, lon, alt]);

	useFrame(({ size, gl }, delta) => {
		gl.localClippingEnabled = true;
		gl.clippingPlanes = [];
		if (tilesRuntime.current) {
			const screenSize = new Vector2(size.width, size.height);
			tilesRuntime.current.update(delta, screenSize, gl.xr.getCamera());
		}
	});

	return model ? (
		<group
			dispose={() => tilesRuntime.current?.dispose()}
			rotation={[Math.PI / 2, 0, 0]}
		>
			<primitive object={model} name="map_root" />
		</group>
	) : null;
};
