import { myPlayer, useMultiplayerState, usePlayerState } from "playroomkit";
import { useRef } from "react";
import { DoubleSide, Matrix4, Vector3, type Mesh } from "three";
import type { HandPose } from "./PlayerStreamer";

const directionVector = new Vector3(1, 0, 0).normalize();
const leftGripMatrix = new Matrix4();
const rightGripMatrix = new Matrix4();
const leftPosition = new Vector3();
const rightPosition = new Vector3();
export const TouchViz = () => {
	const [touches] = useMultiplayerState("touches", []);
	const scaleX = 0.2;
	const scaleY = 0.1;

	const ref = useRef<Mesh>(null);
	const [leftHandPose] = usePlayerState(myPlayer(), "left", undefined) as [
		HandPose | undefined,
		unknown,
	];
	const [rightHandPose] = usePlayerState(myPlayer(), "right", undefined) as [
		HandPose | undefined,
		unknown,
	];

	if (leftHandPose?.gripSpace && rightHandPose?.gripSpace) {
		leftGripMatrix.fromArray(leftHandPose.gripSpace);
		rightGripMatrix.fromArray(rightHandPose.gripSpace);
		leftPosition.setFromMatrixPosition(leftGripMatrix);
		rightPosition.setFromMatrixPosition(rightGripMatrix);
		ref.current?.position.set(
			(leftPosition.x + rightPosition.x) / 2,
			(leftPosition.y + rightPosition.y) / 2,
			(leftPosition.z + rightPosition.z) / 2,
		);
		ref.current?.quaternion.setFromUnitVectors(
			directionVector,
			new Vector3(
				rightPosition.x - leftPosition.x,
				rightPosition.y - leftPosition.y,
				rightPosition.z - leftPosition.z,
			).normalize(),
		);
	}
	return (
		<mesh ref={ref} visible={touches.length >= 0}>
			<mesh rotation={[-0.6, 0, 0.0]} position={[0, 0.03, 0.01]}>
				<planeGeometry args={[scaleX, scaleY]} />
				<meshStandardMaterial color={"green"} side={DoubleSide} />
				{(touches as { x: number; y: number }[]).map((touch, i) => (
					<mesh
						key={`${touch.x}-${touch.y}`}
						position={[
							(touch.x - 0.5) * scaleX,
							(0.5 - touch.y) * scaleY,
							0.01,
						]}
						rotation={[Math.PI / 2, 0, 0]}
					>
						<cylinderGeometry args={[0.01, 0.01, 0.01, 16, 1]} />
						<meshStandardMaterial color="white" />
					</mesh>
				))}
			</mesh>
		</mesh>
	);
};
