import { SketchfabItem } from "../SketchFabItem";
import { SketchFabSpawner } from "../SketchFabSpawner";
import { Map3d } from "../components/livekit/GoogleMaps";
import { ImageTile } from "../components/livekit/ImageTile";
import { LivekitControls } from "../components/livekit/LivekitControls";
import { PointCloud } from "../components/livekit/PointCloud";
import { ScreenshareTile } from "../components/livekit/ScreenshareTile";
import { Ball, BallCannon } from "../menu/Ballcannon";
import { Hive, Resource, WorkerBee } from "../menu/Hive";
import { MainMenu } from "../menu/MainMenu";
import { MultiMesh } from "../menu/MultiMesh";
import { PointLight } from "../menu/PointLight";
import { Room } from "../menu/Room";
import { SelfLocator } from "../ui/SelfLocator";
import { PlayerAvatar } from "./PlayerAvatar";
import type { SceneItem } from "./SceneItem";
import { TouchViz } from "./TouchViz";

export const availableItems: Record<
	string,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	React.ComponentType<SceneItem & any>
> = {
	sketchfab: SketchfabItem,
	locator: SelfLocator,
	sketchfabSpawner: SketchFabSpawner,
	room: Room,
	avatar: PlayerAvatar,
	ball: Ball,
	multiMesh: MultiMesh,
	ballCannon: BallCannon,
	touchViz: TouchViz,
	lifekitControls: LivekitControls,
	mainMenu: MainMenu,
	pointLight: PointLight,
	videoTile: ScreenshareTile,
	imageTile: ImageTile,
	pointCloud: PointCloud,
	map3d: Map3d,
	hive: Hive,
	workerBee: WorkerBee,
	resource: Resource,
};
