import { RPC, isHost, setState } from "playroomkit";
import { useEffect } from "react";
import { Matrix4, Quaternion, Vector3 } from "three";
import {
	ECS,
	Entity,
	JUST_SPAWNED,
	type SyncedEntity
} from "./ecs/state";
import { SerializedEntity, deserializeEntity } from "./serializeEntity";

export interface AddItemProps
	extends Omit<SyncedEntity, "transform" | "id" | "type"> {
	parentId?: string;
	isNetworked?: boolean;
	overrideId?: string;
	scale?: [number, number, number];
	forceUpright?: boolean;
	position?: [number, number, number];
	transform?: Matrix4;
	quaternion?: [number, number, number, number];
}

export const useAddSceneItem = () => {
	return async <T = unknown>(
		type: string,
		item: T,
		{ parentId, ...props }: AddItemProps = {},
	): Promise<string> => {
		const uuid = props.overrideId ?? Math.random().toString();
		const transform =
			props.transform?.toArray() ??
			new Matrix4()
				.compose(
					new Vector3().fromArray(props.position ?? [0, 0, 0]),
					new Quaternion().fromArray(props.quaternion ?? [0, 0, 0, 1]),
					new Vector3().fromArray(props.scale ?? [1, 1, 1]),
				)
				.toArray();
		const entity: SerializedEntity = {
			...props,
			...item,
			type,
			id: uuid,
			transform: transform,
		};
		setState(uuid, item);
		await RPC.call("addSceneItem", entity, RPC.Mode.ALL);
		return uuid;
	};
};

export const useRegisterAddSceneItem = () => {
	useEffect(() => {
		return RPC.register("addSceneItem", async (entity: SerializedEntity) => {
			const itemToBeAdded: Entity = {
				...deserializeEntity(entity),
				lastSync: isHost() ? JUST_SPAWNED : undefined,
			};
			ECS.world.add(itemToBeAdded);
		});
	}, []);
};
