import { useFrame } from "@react-three/fiber";
import type { CompressedEntity } from "../compressEntity";
import { compressEntity } from "../compressEntity";
import { decompressEntity } from "../decompressEntity";
import { ReceiveMode, usePeerCommunication } from "./Peerjs";
import { ECS, FORCE_UPDATE, JUST_SPAWNED } from "./state";

/**
 * dirty items need to be streamed to other players
 */
const allEntities = ECS.world;

export type RealtimeUpdate = {
	[key: string]: CompressedEntity;
};

export const updateIntervalInMs = 1000;
export const MultiplayerSystem = () => {
	const peerComm = usePeerCommunication();

	useFrame((_, dt) => {
		const update: RealtimeUpdate = {};
		const receivedUpdates = peerComm.getUpdates();
		const now = +Date.now();
		for (const entity of allEntities) {
			if (entity.lastSync === JUST_SPAWNED) {
				// wait for next tick and give systems a chance to do something
				// entity.lastSync = FORCE_UPDATE;
				continue;
			}
			if (entity.lastSync !== undefined && entity.lastSync !== FORCE_UPDATE) {
				const delta = now - entity.lastSync;
				if (delta < updateIntervalInMs) {
					// send a state update every second
					continue;
				}
			}
			update[entity.id] = compressEntity(entity);
			entity.lastSync = +Date.now();
		}

		for (const update of receivedUpdates) {
			for (const entity of Object.values(update)) {
				const state = decompressEntity(entity);
				// console.log("updating state", state);
				const e2 = ECS.world.entities.find((e) => e.id === state.id);
				if (e2) {
					ECS.world.update(e2, state);
				}
			}
		}
		if (Object.keys(update).length === 0) {
			return;
		}
		// console.log("sending update", update);
		peerComm.send(update, ReceiveMode.OTHERS);
	});

	return null;
};
