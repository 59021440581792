import { useMultiplayerState } from "playroomkit";
import React from "react";
import type { SceneItem } from "./SceneItem";

export interface SceneItemProps {
	type: string;
	children: React.ReactNode;
}

export interface ExampleItemProps {
	color: string;
}

export const ItemContext = React.createContext<string>("");

export const ItemIdProvider = ({
	id,
	children,
}: {
	id: string;
	children: React.ReactNode;
}) => {
	return <ItemContext.Provider value={id}>{children}</ItemContext.Provider>;
};

export function useItemState<T>(): [
	T & SceneItem,
	(newState: Partial<T & SceneItem>) => void,
] {
	const id = React.useContext(ItemContext);
	const [state, setState] = useMultiplayerState(id, {});
	const stateRef = React.useRef(state);
	React.useEffect(() => {
		stateRef.current = state;
	}, [state]);
	function updateState(newState: Partial<T & SceneItem>) {
		const updatedState = { ...stateRef.current, ...newState };
		setState(updatedState);
	}
	return [state, updateState] as const;
}
