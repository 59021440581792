import type { Entity, RealtimeSyncedEntity, SyncedEntity } from "./ecs/state";

export function compressEntity(entity: Entity): CompressedEntity {
	return {
		type: entity.type,
		id: entity.id,
		colliders: entity.colliders,
		// grabbedBy: entity.grabbedBy,
		handleType: entity.handleType,
		physical: entity.physical,
		avatarState: entity.avatarState,
		velocities: entity.velocities,
		transform: new Float32Array(entity.transform.matrix.toArray()).buffer,
	};
}
export type CompressedEntity = Omit<
	RealtimeSyncedEntity & SyncedEntity,
	"transform"
> & {
	transform: ArrayBufferLike;
};
