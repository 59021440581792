import { useTracks } from "@livekit/components-react";
import type { Track } from "livekit-client";
import { useEffect, useState } from "react";
import { useItemState } from "../../engine/useItemState";
import { VideoTile } from "./VideoTile";

export interface VideoTileProps {
	participantName: string;
	participantIdentity: string;
	sourceType: Track.Source;
}

export const ScreenshareTile = () => {
	const [{ participantName, participantIdentity, sourceType }] =
		useItemState<VideoTileProps>();
	const trackRef = useTracks([sourceType], {
		onlySubscribed: true,
	}).find((track) =>
		participantName === ""
			? track.participant.identity === participantIdentity
			: track.participant.name === participantName,
	);
	const [video, setVideo] = useState<HTMLVideoElement | null>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!trackRef) {
			return;
		}

		const track = trackRef.publication.track;
		const video = track?.attach() as HTMLVideoElement | null;
		setVideo(video);
		return () => {
			track?.detach();
		};
	}, [trackRef?.participant.identity]);

	return video ? <VideoTile video={video} /> : null;
};
