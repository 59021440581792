import { useCubeTexture } from "@react-three/drei";
import { useEffect, useRef } from "react";
import { Matrix4, type InstancedMesh } from "three";

export const MultiMesh = () => {
	const ref = useRef<InstancedMesh>(null);
	useEffect(() => {
		if (!ref.current) {
			return;
		}
		for (let i = 0; i < 20; i++) {
			const angle = (2 * Math.PI * i) / 20; // Divide the circle into 10 parts
			const x = Math.cos(angle) * 1; // Calculate x position on the circle with radius 1m
			const y = Math.sin(angle) * 1; // Calculate y position on the circle with radius 1m
			const matrix = new Matrix4().setPosition(x, 0, y); // Set position in the matrix, assuming y is up in this context
			ref.current.setMatrixAt(i, matrix);
		}
	}, [ref.current]);
	const path = "https://threejs.org/examples/textures/cube/SwedishRoyalCastle/";
	const format = ".jpg";
	const urls = [
		`px${format}`,
		`nx${format}`,
		`py${format}`,
		`ny${format}`,
		`pz${format}`,
		`nz${format}`,
	];
	const envMap = useCubeTexture(urls, { path });
	return (
		<instancedMesh ref={ref} args={[undefined, undefined, 20]}>
			<sphereGeometry args={[0.1, 32, 32]} />
			<meshLambertMaterial
				color={0xfff700}
				refractionRatio={0.95}
				envMap={envMap}
				wireframe={false}
			/>
		</instancedMesh>
	);
};
