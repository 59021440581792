import type { Vector3 } from "@react-three/fiber";
import { useSettingsStore } from "../SettingsStore";

export const CrossHair = ({
	colors = ["red", "green", "blue"],
	scale = [1, 1, 1],
	forceVisible = false,
}: { colors?: string[]; scale?: Vector3; forceVisible?: boolean }) => {
	const { showOrigins } = useSettingsStore();
	return showOrigins || forceVisible ? (
		<group name="Crosshair" scale={scale}>
			<mesh position={[0, 0, 0]}>
				<boxGeometry args={[1, 0.01, 0.01]} />
				<meshPhongMaterial color={colors[0]} />
			</mesh>
			<mesh position={[0, 0.25, 0]}>
				<boxGeometry args={[0.01, 0.5, 0.01]} />
				<meshPhongMaterial color={colors[1]} />
			</mesh>
			<mesh position={[0, 0, -0.005]}>
				<boxGeometry args={[0.01, 0.01, 1]} />
				<meshPhongMaterial color={colors[2]} />
			</mesh>
		</group>
	) : null;
};
